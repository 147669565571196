import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierAssociation } from './CarrierAssociation';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class CarrierAssociationService {
  config: Configuration;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getCarrierAssociations(carrierCode: string): Observable<CarrierAssociation[]> {
    return this.http.get<CarrierAssociation[]>(`${this.config.carrierServiceUrl}${carrierCode}/userRelation`);
  }

  insertUpdateCarrierAssociation(carrierCode: string, ca: CarrierAssociation): Observable<CarrierAssociation[]> {
    return this.http.post<CarrierAssociation[]>(`${this.config.carrierServiceUrl}${carrierCode}/userRelation`, ca);
  }

  deleteCarrierAssociation(carrierCode: string, appUserID: number): Observable<any> {
    return this.http.delete<any>(`${this.config.carrierServiceUrl}${carrierCode}/userRelation/${appUserID}`);
  }
}
