export enum BGStatusType {
  Active = 'Active',
  DoNotUse = 'Do Not Use',
  NoFreight = 'No Freight',
  Pending = 'Pending',
  Probation = 'Probation',
  Target = 'Target',
  DoNotCall = 'Do Not Call',
  OutOfBusiness = 'Out Of Business'
}
