import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierDocument } from './CarrierDocument';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class DocumentService {
  config: Configuration;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getDocuments(carrierCode: string): Observable<CarrierDocument[]> {
    return this.http.get<CarrierDocument[]>(`${this.config.carrierServiceUrl}${carrierCode}/document`).pipe(
      map((j: CarrierDocument[]) =>
        j.map((x) => {
          x.dateUpdated = new Date(`${x.dateUpdated}Z`);
          return x;
        })
      )
    );
  }

  updateCarrierDocument(carrierCode: string, carrierDocument: CarrierDocument): Observable<CarrierDocument> {
    return this.http.put<CarrierDocument>(`${this.config.carrierServiceUrl}${carrierCode}/document`, carrierDocument);
  }

  deleteCarrierDocument(carrierCode: string, document: CarrierDocument) {
    return this.http.delete(`${this.config.carrierServiceUrl}${carrierCode}/document/${document.carrierDocumentID}`);
  }

  downloadCarrierDocumentImage(carrierCode: string, carrierDocumentID: number): Observable<Blob> {
    const downloadUrl = `${this.config.carrierServiceUrl}${carrierCode}/document/${carrierDocumentID}/image`;
    const file = this.http.get<Blob>(downloadUrl, {
      responseType: 'blob' as 'json',
    });
    return file;
  }
}
