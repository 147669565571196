import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShipmentService } from './shipment.service';
import { CommonService } from '../../../Services/common.service';
import { LoadHistory } from './LoadHistory';
import { AppState } from '../../../appstate.model';
import { Configuration } from '../../../shared/configuration/Configuration';

@Component({
  selector: 'truckload-load-history',
  templateUrl: './load-history.component.html',
  styleUrls: ['./load-history.component.scss'],
})
export class LoadHistoryComponent implements OnInit {
  public loading: boolean;
  @Input()
  dotNumber: string;
  @Input()
  mcNumber: string;

  loadHistoryGridData: LoadHistory[];
  config: Configuration;

  constructor(public shipmentService: ShipmentService, public commonService: CommonService, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  ngOnInit() {
    this.loading = true;
    this.shipmentService.getLoadHistory(this.dotNumber, this.mcNumber).subscribe((data) => {
      this.loadHistoryGridData = data;
      this.loading = false;
    });
  }

  goToCarrierOffer(loadHistory: LoadHistory) {
    window.open(`${this.config.backofficeShipmentEditUrl}/shipment-edit/${loadHistory.shipmentID}`);
  }
}
