import { Action } from '@ngrx/store';
import { SearchState } from '../models/search.model';
import { CarrierSearchResult } from '../models/CarrierSearchResults';
import { CarrierLaneSearchResult } from '../models/CarrierLaneSearchResults';
import { CarrierNameSearchParamters } from '../models/CarrierNameSearchParamters';
import { CarrierIdentitySearchParameters } from '../models/CarrierIdentitySearchParameters';
import { CarrierLocationSearchParameters } from '../models/CarrierLocationSearchParameters';
import { CarrierLaneSearchParameters } from '../models/CarrierLaneSearchParameters';
import { CarrierSearchParameters } from '../models/CarrierSearchParameters';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CarrierTruckPostingSearchParameters } from '../models/CarrierTruckPostingSearchParameters';
import { CarrierTruckpostingSearchResult } from '../models/CarrierTruckpostingSearchResults';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

export const SELECT_SEARCH_TYPE = '[SEARCH] Select Search Type';
export class SelectSearchType implements Action {
  readonly type = SELECT_SEARCH_TYPE;
  constructor(public params: CarrierSearchParameters) { }
}

// initially triggers loading search and setting up state
export const SEARCH_LOAD = '[SEARCH API] Load Search';
export const SEARCH_LOAD_SUCCESS = '[SEARCH API] Search Load Success';
export const SEARCH_LOAD_ERROR = '[SEARCH API] Search Load Error';
export class SearchLoad implements Action {
  readonly type = SEARCH_LOAD;
}
export class SearchLoadSuccess implements Action {
  readonly type = SEARCH_LOAD_SUCCESS;
  constructor(public state: SearchState) { }
}
export class SearchLoadError implements Action {
  readonly type = SEARCH_LOAD_ERROR;
  constructor(public errorMessage: string) { }
}

// name search
export const SEARCH_NAME = '[SEARCH API] Name Search';
export class SearchName implements Action {
  readonly type = SEARCH_NAME;
  constructor(public params: CarrierNameSearchParamters) { }
}

// id search
export const SEARCH_ID = '[SEARCH API] ID Search';
export class SearchID implements Action {
  readonly type = SEARCH_ID;
  constructor(public params: CarrierIdentitySearchParameters) { }
}

// location search
export const SEARCH_LOCATION = '[SEARCH API] Location Search';
export class SearchLocation implements Action {
  readonly type = SEARCH_LOCATION;
  constructor(public params: CarrierLocationSearchParameters) { }
}


// truckposting search
export const SEARCH_TRUCKPOSTING = '[SEARCH API] TruckPosting Search';
export class SearchTruckPosting implements Action {
  readonly type = SEARCH_TRUCKPOSTING;
  constructor(public params: CarrierTruckPostingSearchParameters) { }
}
// carrier search success/fail (name, id, location)
export const SEARCH_TRUCKPOSTING_SUCCESS = '[SEARCH API] Search Truckposting Success';
export const SEARCH_TRUCKPOSTING_ERROR = '[SEARCH API] Search Truckposting Error';
export class SearchTruckpostingSuccess implements Action {
  readonly type = SEARCH_TRUCKPOSTING_SUCCESS;
  constructor(public results: CarrierTruckpostingSearchResult[]) { }
}
export class SearchTruckpostingError implements Action {
  readonly type = SEARCH_TRUCKPOSTING_ERROR;
  constructor(public errorMessage: string) { }
}



// carrier search success/fail (name, id, location)
export const SEARCH_CARRIER_SUCCESS = '[SEARCH API] Search Carrier Success';
export const SEARCH_CARRIER_ERROR = '[SEARCH API] Search Carrier Error';
export class SearchCarrierSuccess implements Action {
  readonly type = SEARCH_CARRIER_SUCCESS;
  constructor(public results: CarrierSearchResult[]) { }
}
export class SearchCarrierError implements Action {
  readonly type = SEARCH_CARRIER_ERROR;
  constructor(public errorMessage: string) { }
}

// preferred lane
export const SEARCH_PREFERRED_LANE = '[SEARCH API] Preferred Lane Search';
export class SearchPreferredLane implements Action {
  readonly type = SEARCH_PREFERRED_LANE;
  constructor(public params: CarrierLaneSearchParameters) { }
}

// carrier lane success/fail (preferred lane and probably truck posts search that will be added later)
export const SEARCH_CARRIER_LANE_SUCCESS = '[SEARCH API] Search Carrier Lane Success';
export const SEARCH_CARRIER_LANE_ERROR = '[SEARCH API] Search Carrier Lane Error';
export class SearchCarrierLaneSuccess implements Action {
  readonly type = SEARCH_CARRIER_LANE_SUCCESS;
  constructor(public results: CarrierLaneSearchResult[]) { }
}
export class SearchCarrierLaneError implements Action {
  readonly type = SEARCH_CARRIER_LANE_ERROR;
  constructor(public errorMessage: string) { }
}

export const CARRIER_GRID_SORT = '[SEARCH] Grid Sort';
export const CARRIER_GRID_PAGING = '[SEARCH] Grid Paging';

export const CARRIER_LANE_GRID_SORT = '[SEARCH] Lane Grid Sort';
export const CARRIER_LANE_GRID_PAGING = '[SEARCH] Carrier Lane Grid Paging';

export const CARRIER_TRUCKPOSTING_GRID_SORT = '[SEARCH] TruckPosting Grid Sort';
export const CARRIER_TRUCKPOSTING_GRID_PAGING = '[SEARCH] TruckPosting Grid Paging';

export class CarrierGridSort implements Action {
  readonly type = CARRIER_GRID_SORT;
  constructor(public sort: SortDescriptor[]) { }
}

export class CarrierGridPaging implements Action {
  readonly type = CARRIER_GRID_PAGING;
  constructor(public paging: PageChangeEvent) { }
}

export class CarrierLaneGridSort implements Action {
  readonly type = CARRIER_LANE_GRID_SORT;
  constructor(public sort: SortDescriptor[]) { }
}

export class CarrierLaneGridPaging implements Action {
  readonly type = CARRIER_LANE_GRID_PAGING;
  constructor(public paging: PageChangeEvent) { }
}

export class CarrierTruckpostingGridSort implements Action {
  readonly type = CARRIER_TRUCKPOSTING_GRID_SORT;
  constructor(public sort: SortDescriptor[]) { }
}

export class CarrierTruckpostingGridPaging implements Action {
  readonly type = CARRIER_TRUCKPOSTING_GRID_PAGING;
  constructor(public paging: PageChangeEvent) { }
}

export type Actions =
  | SelectSearchType
  | SearchLoad
  | SearchLoadSuccess
  | SearchLoadError
  | SearchName
  | SearchID
  | SearchLocation
  | SearchTruckPosting
  | SearchTruckpostingSuccess
  | SearchTruckpostingError
  | SearchCarrierSuccess
  | SearchCarrierError
  | SearchPreferredLane
  | SearchCarrierLaneSuccess
  | SearchCarrierLaneError
  | CarrierGridSort
  | CarrierGridPaging
  | CarrierLaneGridSort
  | CarrierLaneGridPaging
  | CarrierTruckpostingGridSort
  | CarrierTruckpostingGridPaging;

