import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class PageService {
  private pageTitleSource = new Subject<string>();

  pageTitle$ = this.pageTitleSource.asObservable();

  constructor() {}

  setPageTitle(title: string) {
    this.pageTitleSource.next(title);
  }
}
