import { Component, OnInit, Input } from '@angular/core';
import { TruckPost } from '../../models/TruckPost';

@Component({
  selector: 'truckload-carrier-truck-posting-details',
  templateUrl: './carrier-truck-posting-details.component.html',
  styleUrls: ['./carrier-truck-posting-details.component.scss'],
})
export class CarrierTruckPostingDetailsComponent implements OnInit {
  @Input()
  truckPost: TruckPost;
  constructor() {}

  ngOnInit() {}
}
