import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierFileDetails } from '../models/CarrierFileDetails';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { tap } from 'rxjs/operators';
import { CarrierDetailsState } from '../models/carrier-details-state.model';
import { CarrierStatus } from '../models/CarrierStatus';
import { CarrierFile } from '../carrier-file/CarrierFile';

@Injectable()
export class DetailsService {
  config: Configuration;
  carrier: CarrierFileDetails;
  $carrierSubject: BehaviorSubject<CarrierFileDetails>;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
    this.$carrierSubject = new BehaviorSubject<CarrierFileDetails>(null);
  }

  getState(carrierCode: string): Observable<CarrierDetailsState> {
    return new Observable<CarrierDetailsState>((observer) => {
      this.http.get<CarrierFileDetails>(`${this.config.carrierServiceUrl}${carrierCode}/summary`).subscribe(
        (x) => {
          var newState = new CarrierDetailsState();
          x.isCertified = x.isCertified == null ? false : x.isCertified;
          newState.details = x;
          newState.status = new CarrierStatus();
          newState.status.isManuallyMonitored = x.isManual;
          newState.status.status = x.status;
          observer.next(newState);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getCarrierFileDetails(carrierCode: string): Observable<CarrierFileDetails> {
    return this.http.get<CarrierFileDetails>(`${this.config.carrierServiceUrl}${carrierCode}/summary`).pipe(
      tap((c) => {
        this.carrier = c;
        this.$carrierSubject.next(this.carrier);
      })
    );
  }

  updateCarrierDetails(carrierCode: string, carrier: CarrierFileDetails) {
    return this.http.put<CarrierFileDetails>(`${this.config.carrierServiceUrl}carrier/${carrierCode}`, carrier).pipe(
      tap((c) => {
        this.carrier = c;
        this.$carrierSubject.next(this.carrier);
      })
    );
  }

  updateCarrierStatus(carrierCode: string, carrierStatus: CarrierStatus): Observable<CarrierFile> {
    const carrierStutsUpdateCmd = {
      status: carrierStatus.status,
      isManual: carrierStatus.isManuallyMonitored,
      isTruckerToolsQualified: carrierStatus.isTruckerToolsQualified,
      isAuthorizedToQuickBook: carrierStatus.isAuthorizedToQuickBook,
      carrierTier: carrierStatus.carrierTier,
    };
    return this.http.put<CarrierFile>(`${this.config.carrierServiceUrl}${carrierCode}/status`, carrierStutsUpdateCmd);
  }

  updateCarrierCrmLeadStatus(carrierCode: string, carrierCrmLeadStatus: string): Observable<CarrierFile> {
    const crmLeadStatus = { status: carrierCrmLeadStatus };
    return this.http.put<CarrierFile>(`${this.config.carrierServiceUrl}${carrierCode}/crmleadstatus`, crmLeadStatus);
  }

  loadCarrierCrmLeadStatus(carrierCode: string): Observable<string> {
    {
      return new Observable<string>((observer) => {
        this.http.get(`${this.config.carrierServiceUrl}${carrierCode}/crmleadstatus`, { responseType: 'text' }).subscribe(
          (x) => {
            observer.next(x);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
      });
    }
  }
}
