import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RefValue } from './RefValue';
import { RefTruckType } from './RefTruckType';
import { RefContact } from './RefContact';
import { RefServiceDC } from './RefServiceDC';
import { RefDocument } from './RefDocument';
import { RefCertification } from './RefCertification';
import { RefInsurance } from './RefInsurance';
import { RefDisposition } from './RefDisposition';
import { RefActivity } from './RefActivity';
import { RefCategory } from './RefCategory';
import { RefDirection } from './RefDirection';
import { Cacheable } from 'ngx-cacheable';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../configuration/Configuration';

@Injectable()
export class RefService {
  config: Configuration;
  constructor(private http: HttpClient, store: Store<AppState>) {
    store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  @Cacheable()
  getRefServices(): Observable<RefServiceDC[]> {
    return this.http.get<RefServiceDC[]>(`${this.config.carrierServiceUrl}reference/service`);
  }

  @Cacheable()
  getCertifications(): Observable<RefCertification[]> {
    return this.http.get<RefCertification[]>(`${this.config.carrierServiceUrl}reference/certification`);
  }

  @Cacheable()
  getTruckTypes(): Observable<RefTruckType[]> {
    return this.http.get<RefTruckType[]>(`${this.config.carrierServiceUrl}reference/truck`);
  }

  @Cacheable()
  getCarrierCargoTypes(): Observable<RefValue[]> {
    return this.http.get<RefValue[]>(`${this.config.carrierServiceUrl}reference/cargo`);
  }

  @Cacheable()
  getCarrierDocumentTypes(): Observable<RefDocument[]> {
    return this.http.get<RefDocument[]>(`${this.config.carrierServiceUrl}reference/document`);
  }

  @Cacheable()
  getDispositionTypes(): Observable<RefDisposition[]> {
    return this.http.get<RefDisposition[]>(`${this.config.carrierServiceUrl}reference/disposition`);
  }

  @Cacheable()
  getActivityTypes(): Observable<RefActivity[]> {
    return this.http.get<RefActivity[]>(`${this.config.carrierServiceUrl}reference/activity`);
  }

  @Cacheable()
  getInsuranceTypes(): Observable<RefInsurance[]> {
    return this.http.get<RefInsurance[]>(`${this.config.carrierServiceUrl}reference/insurance`);
  }

  @Cacheable()
  getCategories(): Observable<RefCategory[]> {
    return this.http.get<RefCategory[]>(`${this.config.carrierServiceUrl}reference/category`);
  }

  @Cacheable()
  getChannels(): Observable<RefValue[]> {
    return this.http.get<RefValue[]>(`${this.config.carrierServiceUrl}reference/channels`);
  }

  @Cacheable()
  getLocations(): Observable<RefValue[]> {
    return this.http.get<RefValue[]>(`${this.config.carrierServiceUrl}reference/location`);
  }

  @Cacheable()
  getUserTypes(): Observable<RefValue[]> {
    return this.http.get<RefValue[]>(`${this.config.carrierServiceUrl}reference/user-types`);
  }

  @Cacheable()
  getContactTypes(): Observable<RefContact[]> {
    return this.http.get<RefContact[]>(`${this.config.carrierServiceUrl}reference/contact`);
  }

  @Cacheable()
  getAddressTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.carrierServiceUrl}reference/addressTypes`);
  }

  @Cacheable()
  getDirections(): Observable<RefDirection[]> {
    return this.http.get<RefDirection[]>(`${this.config.carrierServiceUrl}reference/directions`);
  }
}
