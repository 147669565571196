import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ContactService, VerificationQueryParams } from '../carrier/carrier-file/carrier-file-contacts/contact.service';

@Component({
  selector: 'verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
    isValid = false;
    isVerifying = true;

  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    ) {}

  ngOnInit() {
    // Access query parameters using paramMap
    this.route.queryParams.subscribe(async (params) => {
        const hasValidParams = this.validateParams(params);

        if (!hasValidParams) {
            this.isValid = false;
            this.isVerifying = false;
            return;
        }

        try {
            await this.contactService.confirmVerification(params as VerificationQueryParams).toPromise();
            this.isValid = true;
            this.isVerifying = false;
        } catch(e) {
            this.isVerifying = false;
            this.isValid = false;
        }
    });
  }

  isDoneWithError() {
    return !this.isVerifying && !this.isValid;
  }

  isDoneWithSuccess() {
    return !this.isVerifying && this.isValid;
  }

  validateParams(params: Params) {
    return Boolean(params.resp && params.ts && params.sig && params.code);
  }
}
