import { OnInit, Component } from '@angular/core';
import { CarrierLocationSearchParameters } from '../../models/CarrierLocationSearchParameters';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import * as SearchActions from '../../actions/search.actions';

@Component({
  selector: 'location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {
  carrierLocationSearchParams: CarrierLocationSearchParameters;
  truckTypes: string[];
  serviceTypes: string[];
  certificationTypes: string[];

  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Search.carrierLocationSearchParams)
      .subscribe((x) => {
        this.carrierLocationSearchParams = x;
      });

    this.store
      .select((x) => x.Search.truckTypes)
      .subscribe((x) => {
        this.truckTypes = x;
      });

    this.store
      .select((x) => x.Search.serviceTypes)
      .subscribe((x) => {
        this.serviceTypes = x;
      });

    this.store
      .select((x) => x.Search.certificationTypes)
      .subscribe((x) => {
        this.certificationTypes = x;
      });
  }

  ngOnInit(): void {}

  submitLocationSearch() {
    this.store.dispatch(new SearchActions.SearchLocation(this.carrierLocationSearchParams));
  }
}
