import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageService } from '../../Services/page.service';
import { AuthService } from '../../authentication/_services/auth.service';
import { RefService } from '../../shared/references/ref.service';
import { identitiesValidator } from './carrier-id-validation.directive';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CarrierSyncService } from '../services/carrier-sync.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'truckload-sync-carrier-file',
  templateUrl: './sync-carrier-file.component.html',
  styleUrls: ['./sync-carrier-file.component.scss'],
})
export class SyncCarrierFileComponent implements OnInit {
  identitiesForm: FormGroup;
  serverError: string;
  constructor(
    public refService: RefService,
    public carrierSyncService: CarrierSyncService,
    private pageService: PageService,
    public authService: AuthService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.pageService.setPageTitle('New Manual Carrier');

    this.identitiesForm = new FormGroup(
      {
        dotNumber: new FormControl(null),
        mcNumber: new FormControl(null),
      },
      { validators: [identitiesValidator] }
    );
  }
  get dotNumber() {
    return this.identitiesForm.get('dotNumber');
  }

  get mcNumber() {
    return this.identitiesForm.get('mcNumber');
  }

  confirmSync(){
    this.dialogService.open({
      title: 'Confirm',
      content: 'Are you sure you want to sync this carrier?',
      actions: [
        { text: 'Yes', primary: true },
        { text: 'No' },
      ],
      width: 450,
      height: 200,
      minWidth: 250,
    }).result.subscribe((res) => {
      if (res instanceof DialogCloseResult) {
        return;
      }
      if (res.text === 'Yes') {
        this.submit();
      }
    });
  }

  submit() {
    let dot = this.dotNumber.value;
    let mc = this.mcNumber.value ? this.mcNumber.value.replace(/[^0-9]/g, '') : null;
    let appUser = this.authService.loggedAppUser;

    this.carrierSyncService.syncHighwayCarrier(dot, mc, 'needs_to_onboard', appUser.email).subscribe(
      (result) => {
        this.notificationService.show({
          content: `Carrier sync details: ${result} `,
          cssClass: 'button-notification',
          animation: { type: 'fade', duration: 3000 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'info', icon: true },
        });
      },
      (err) => {
        this.notificationService.show({
          content: `Error syncing carrier details: ${err.message}`,
          cssClass: 'button-notification',
          animation: { type: 'fade', duration: 3000 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
        });
      }
    );
  }
}
