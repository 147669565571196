
import {map} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { parseDate } from '@telerik/kendo-intl';
import { PortfolioSearchResult } from '../models/PortfolioSearchResult';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../../shared/configuration/Configuration';

@Injectable()
export class PortfolioService {
  public portfolioRep: string;
  public portfolioRepChangedBehavior: BehaviorSubject<string>;
  config: Configuration;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
    this.initUser();
    this.portfolioRepChangedBehavior = new BehaviorSubject<string>(this.portfolioRep);
  }

  public getPortFolio(): Observable<PortfolioSearchResult[]> {
    const url = this.portfolioRep
      ? `${this.config.carrierServiceUrl}search/following?userName=${this.portfolioRep}`
      : `${this.config.carrierServiceUrl}search/following`;
    return this.http.get<PortfolioSearchResult[]>(url).pipe(map((x) => {
      x.forEach((c) => {
        c.lastContacted = parseDate('' + c.lastContacted);
      });
      return x;
    }));
  }

  public portfolioRepChanged(rep: string) {
    this.portfolioRep = rep;
    this.portfolioRepChangedBehavior.next(rep);
  }

  private initUser() {
    var appUser = localStorage.getItem('portfolioRep');
    this.portfolioRep = appUser ? JSON.parse(appUser).appUserName : null;
  }
}
