import { Action } from '@ngrx/store';
import { CarrierIdentityState } from '../models/carrier-identity-state.model';
import { CarrierIdentities, CarrierIdentity } from '../models/CarrierIdentity';
import { CarrierName } from '../models/CarrierName';

export const LOAD_CARRIER_IDENTITY = '[CARRIER] Load Carrier Identity';
export const LOAD_CARRIER_IDENTITY_SUCCESS = '[CARRIER] Load Carrier Identity Success';
export const LOAD_CARRIER_IDENTITY_ERROR = '[CARRIER] Load Carrier Identity Error';

export class LoadCarrierIdentity implements Action {
  readonly type = LOAD_CARRIER_IDENTITY;
  constructor(public carrierCode: string) {}
}

export class LoadCarrierIdentitySuccess implements Action {
  readonly type = LOAD_CARRIER_IDENTITY_SUCCESS;
  constructor(public state: CarrierIdentityState) {}
}

export class LoadCarrierIdentityError implements Action {
  readonly type = LOAD_CARRIER_IDENTITY_ERROR;
  constructor(public errorMessage: string) {}
}

export const UPDATE_CARRIER_IDENTITY = '[CARRIER] Update Carrier Identity';
export const UPDATE_CARRIER_IDENTITY_SUCCESS = '[CARRIER] Update Carrier Identity Success';
export const UPDATE_CARRIER_IDENTITY_ERROR = '[CARRIER] Update Carrier Identity Error';

export class UpdateCarrierIdentity implements Action {
  readonly type = UPDATE_CARRIER_IDENTITY;
  constructor(public carrierCode: string, public identities: CarrierIdentities) {}
}

export class UpdateCarrierIdentitySuccess implements Action {
  readonly type = UPDATE_CARRIER_IDENTITY_SUCCESS;
  constructor(public result: CarrierIdentities) {}
}

export class UpdateCarrierIdentityError implements Action {
  readonly type = UPDATE_CARRIER_IDENTITY_ERROR;
  constructor(public errorMessage: string) {}
}

export const UPDATE_CARRIER_NAME = '[CARRIER] Update Carrier Name';
export const UPDATE_CARRIER_NAME_SUCCESS = '[CARRIER] Update Carrier Name Success';
export const UPDATE_CARRIER_NAME_ERROR = '[CARRIER] Update Carrier Name Error';

export class UpdateCarrierName implements Action {
  readonly type = UPDATE_CARRIER_NAME;
  constructor(public carrierCode: string, public carrierName: CarrierName) {}
}

export class UpdateCarrierNameSuccess implements Action {
  readonly type = UPDATE_CARRIER_NAME_SUCCESS;
  constructor(public result: CarrierName) {}
}

export class UpdateCarrierNameError implements Action {
  readonly type = UPDATE_CARRIER_NAME_ERROR;
  constructor(public errorMessage: string) {}
}

export type Actions =
  | LoadCarrierIdentity
  | LoadCarrierIdentitySuccess
  | LoadCarrierIdentityError
  | UpdateCarrierIdentity
  | UpdateCarrierIdentitySuccess
  | UpdateCarrierIdentityError
  | UpdateCarrierName
  | UpdateCarrierNameSuccess
  | UpdateCarrierNameError;
