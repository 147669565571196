import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as CarrierIdentityActions from '../actions/carrier-identity.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CarrierIdentityService } from '../services/identity-service';

@Injectable()
export class CarrierIdentityEffects {
  @Effect()
  loadCarrierIdentity = this.actions$.pipe(
    ofType(...[CarrierIdentityActions.LOAD_CARRIER_IDENTITY]),
    mergeMap((action: CarrierIdentityActions.LoadCarrierIdentity) =>
      this.identityService.getState(action.carrierCode).pipe(
        map((result) => {
          return new CarrierIdentityActions.LoadCarrierIdentitySuccess(result);
        }),
        catchError((x) => {
          return of(new CarrierIdentityActions.LoadCarrierIdentityError(x.error));
        })
      )
    )
  );

  @Effect()
  updateCarrierDetails = this.actions$.pipe(
    ofType(...[CarrierIdentityActions.UPDATE_CARRIER_IDENTITY]),
    mergeMap((action: CarrierIdentityActions.UpdateCarrierIdentity) =>
      this.identityService.updateCarrierIDs(action.carrierCode, action.identities).pipe(
        map((result) => {
          return new CarrierIdentityActions.UpdateCarrierIdentitySuccess(result);
        }),
        catchError((x) => {
          return of(new CarrierIdentityActions.UpdateCarrierIdentityError(x.error));
        })
      )
    )
  );

  @Effect()
  updateCarrierName = this.actions$.pipe(
    ofType(...[CarrierIdentityActions.UPDATE_CARRIER_NAME]),
    mergeMap((action: CarrierIdentityActions.UpdateCarrierName) =>
      this.identityService.updateCarrierName(action.carrierCode, action.carrierName).pipe(
        map((result) => {
          return new CarrierIdentityActions.UpdateCarrierNameSuccess(result);
        }),
        catchError((x) => {
          return of(new CarrierIdentityActions.UpdateCarrierNameError(x.error));
        })
      )
    )
  );

  constructor(private actions$: Actions, private identityService: CarrierIdentityService) { }
}
