import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

import { Store } from '@ngrx/store';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AppState } from '../../../../appstate.model';
import { Submitable } from '../../../../carrier/models/Submitable';
import { NoCapacity, NoCapacityLocation } from '../../models/CarrierNoCapacity';
import * as TruckPostActions from '../../actions/truck-post.actions';

@Component({
  selector: 'carrier-no-capacity-edit',
  templateUrl: './carrier-no-capacity-edit.component.html',
  styleUrls: ['./carrier-no-capacity-edit.component.scss'],
})
export class CarrierNoCapacityEditComponent extends Submitable implements OnInit {
  @Input()
  public carrierCode: string;
  @Input()
  isNew: boolean;
  @Input()
  originalNoCapacity: NoCapacity;

  noCapacityEditForm: FormGroup;
  currentActivityId: number;

  constructor(private store: Store<AppState>, dialog: DialogRef) {
    super(dialog);

    store
      .select((x) => x.TruckPost.savingNoCapacity)
      .subscribe((x) => {
        if (x) {
          this.disableAndSubmitButton();
        } else {
          if (this.noCapacityEditForm) this.dialog.close();
        }
      });

    store
      .select((x) => x.TruckPost.activityId)
      .subscribe((x) => {
        this.currentActivityId = x;
      });
  }

  ngOnInit() {
    if (this.isNew) {
      this.noCapacityEditForm = new FormGroup({
        emptyDateTime: new FormControl(new Date(), [Validators.required, this.validateDate]),
        originAddress: new FormControl(null, Validators.required),
        destinationAddress: new FormControl(null, Validators.required),
        truckTypeCode: new FormControl('', Validators.required),
        truckLengthValue: new FormControl(53, Validators.required),
      });
    } else {
      this.noCapacityEditForm = new FormGroup({
        emptyDateTime: new FormControl(this.originalNoCapacity.emptyDateTime, [Validators.required, this.validateDate]),
        originAddress: new FormControl(this.originalNoCapacity.originAddress as NoCapacityLocation, Validators.required),
        destinationAddress: new FormControl(this.originalNoCapacity.destinationAddress as NoCapacityLocation, Validators.required),
        truckTypeCode: new FormControl(this.originalNoCapacity.truckTypeCode, Validators.required),
        truckLengthValue: new FormControl(this.originalNoCapacity.truckLengthValue, Validators.required),
      });
    }
  }

  private validateDate(control: AbstractControl): ValidationErrors[] {
    var errors = [];
    var editDate = new Date(control.value);
    editDate.setHours(0, 0, 0);
    editDate.setSeconds(0, 0);
    var curDate = new Date();
    curDate.setHours(0, 0, 0);
    curDate.setSeconds(0, 0);
    if (editDate < curDate) errors.push('Date entered earlier than today');
    return errors;
  }

  private closeForm(): void {
    this.dialog.close();
  }

  public onSave(e: MouseEvent): void {
    const edit = Object.assign({}, this.noCapacityEditForm.value) as NoCapacity;
    edit.carrierActivityID = this.currentActivityId;

    if (this.isNew) {
      edit.carrierNoCapacityID = 0;
      this.store.dispatch(new TruckPostActions.CreateNoCapacity(this.carrierCode, edit));
    } else {
      this.originalNoCapacity.truckTypeCode = edit.truckTypeCode;
      this.originalNoCapacity.truckLengthValue = edit.truckLengthValue;
      this.originalNoCapacity.emptyDateTime = edit.emptyDateTime;
      this.originalNoCapacity.originAddress = edit.originAddress;
      this.originalNoCapacity.destinationAddress = edit.destinationAddress;
      this.store.dispatch(new TruckPostActions.EditNoCapacity(this.carrierCode, this.originalNoCapacity));
    }
  }

  public onCancel(e: MouseEvent): void {
    this.dialog.close();
  }
}
