import { Injectable } from '@angular/core';
import { Effect, ofType, Actions, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';
import { Configuration } from '../configuration/Configuration';
import { FeatureFlagService } from './feature-flag.service';
import * as AuthActions from '../../authentication/actions/authentication.actions';
import * as AppActions from '../../app.actions';

@Injectable()
export class FeatureFlagEffects {
  constructor(private actions$: Actions, private featureFlagService: FeatureFlagService) { }
  @Effect()
  $init = this.actions$.pipe(
    ofType(AuthActions.USER_AUTHENTICATION_SUCCESS),
    mergeMap((action: AuthActions.UserAuthenticationSuccess) =>

      this.featureFlagService.init(action.config, action.user).pipe(
        map((flags) => {
          return new AppActions.LoadFeatureFlagsSuccess(flags);
        })
      )
    )
  );
}
