import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { CarrierContactService } from './carrier-contact.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'truckload-carrier-contact-select',
  templateUrl: './carrier-contact-select.component.html',
  styleUrls: ['./carrier-contact-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarrierContactSelectComponent),
      multi: true,
    },
  ],
})
export class CarrierContactSelectComponent implements OnInit, ControlValueAccessor {
  @Output() selectedNameChange = new EventEmitter();
  @Input() carrierCode: string;
  @Input() isRequired: boolean;
  private contactNames: string[];
  private _name = '';
  private onChange: any;

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.selectedName = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  @Input()
  set selectedName(name: string) {
    this._name = name;
    this.selectedNameChange.emit(this._name);
    if (this.onChange) {
      this.onChange(name);
    }
  }
  get selectedName(): string {
    return this._name;
  }

  constructor(private contactService: CarrierContactService) {}

  ngOnInit() {
    let namesList = [];
    this.contactService.getCarrierContacts(this.carrierCode).subscribe((response) => {
      if (!this.isRequired) {
        namesList.push('');
      }
      response.forEach((c) => {
        namesList.push(this.toTitleCase(c.name));
      });
      namesList = this.unique(namesList).sort();
      this.contactNames = namesList;
    });
  }
  private unique(items: Array<string>): Array<string> {
    const uniqueItems = new Array<string>();
    items.forEach((item) => {
      if (uniqueItems.indexOf(item) < 0) {
        uniqueItems.push(item);
      }
    });
    return uniqueItems;
  }

  private toTitleCase(value: string) {
    return value.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
