import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '../../../authentication/_services/auth.service';

@Injectable()
export class UserEnabledGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    if (this.authService.isEnabled() || (next.data && next.data.isPublic)) {
      return true;
    }

    this.authService.redirectNotAuthorized();
    return false;
  }
}
