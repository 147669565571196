import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiCheckFilterComponent } from './multicheck-filter/multicheck-filter.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GoogleAddressLookupComponent } from './google-address-lookup/google-address-lookup.component';
import { ComboBoxModule, DropDownListModule, MultiSelectModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AppUserAutoCompleteComponent } from './appuser-autocomplete/appuser-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { PhonePipe } from './pipes/phone-pipe';
import { RefService } from '../shared/references/ref.service';
import { AddCarrierAssociationComponent } from './add-carrier-association/add-carrier-association.component';
import { FollowCarrierToggleComponent } from './follow-carrier-toggle/follow-carrier-toggle.component';
import { FollowToggleService } from './follow-carrier-toggle/follow-toggle.service';
import { CarrierContactService } from './carrier-contact-select/carrier-contact.service';
import { CarrierTruckTypeSelectComponent } from './carrier-truck-type-select/carrier-truck-type-select.component';
import { CarrierContactSelectComponent } from './carrier-contact-select/carrier-contact-select.component';
import { CarrierTruckTypeService } from './carrier-truck-type-select/carrier-truck-type.service';
import { CarrierOfferComponent } from './carrier-offer/carrier-offer.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { UploadModule } from '@progress/kendo-angular-upload';
import { WindowModule, DialogModule } from '@progress/kendo-angular-dialog';
import { DatePickerModule, DateInputModule, TimePickerModule, DateRangeModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IntlModule } from '@progress/kendo-angular-intl';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { CarrierOfferService } from './carrier-offer/carrier-offer.service';
import { StoreModule } from '@ngrx/store';
import { appReducer } from '../app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '../app.effects';
import { FeatureFlagEffects } from './feature-flag/feature-flag.effects';
import { FeatureFlagService } from './feature-flag/feature-flag.service';

@NgModule({
  imports: [
    CommonModule,
    InputsModule,
    ComboBoxModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ButtonModule,
    UploadModule,
    DropDownListModule,
    WindowModule,
    MultiSelectModule,
    DatePickerModule,
    ButtonsModule,
    DateInputModule,
    DateInputsModule,
    DateRangeModule,
    DialogModule,
    TimePickerModule,
    InputsModule,
    LayoutModule,
    IntlModule,
    ComboBoxModule,
    DropDownsModule,
    NotificationModule,
    DropDownListModule,
    StoreModule.forFeature('Configuration', appReducer),
    EffectsModule.forFeature([AppEffects, FeatureFlagEffects]),
  ],
  declarations: [
    MultiCheckFilterComponent,
    GoogleAddressLookupComponent,
    AppUserAutoCompleteComponent,
    AddressLookupComponent,
    PhonePipe,
    AddCarrierAssociationComponent,
    FollowCarrierToggleComponent,
    CarrierTruckTypeSelectComponent,
    CarrierContactSelectComponent,
    CarrierOfferComponent,
  ],
  exports: [
    MultiCheckFilterComponent,
    GoogleAddressLookupComponent,
    FollowCarrierToggleComponent,
    AddressLookupComponent,
    AppUserAutoCompleteComponent,
    AddCarrierAssociationComponent,
    CarrierTruckTypeSelectComponent,
    CarrierContactSelectComponent,
    CarrierOfferComponent,
    PhonePipe,
    DateInputsModule,
  ],
  providers: [RefService, FollowToggleService, CarrierContactService, CarrierTruckTypeService, CarrierOfferService, FeatureFlagService],
  entryComponents: [
    CarrierOfferComponent
  ]
})
export class SharedModule { }
