import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../../../shared/configuration/configuration.service';
import { TruckPosting, TruckPost } from '../dto/TruckPostDC';
import { ShipmentSearchCriteriaModel, TruckMatch } from '../dto/TruckMatchDC';
import { CarrierSummaryDC } from '../../dto/CarrierSummaryDC';
import { CarrierTruckMatchingService } from './carrier-truck-matching.service';

@Injectable()
export class CarrierPostMatchService {
  constructor(private matchService: CarrierTruckMatchingService, private configService: ConfigurationService) { }

  // get truck posts
  get(carrierCode: string): Observable<TruckPosting> {
    return this.matchService.get(carrierCode);
  }

  cacheId(truckPostId: number): string {
    return `CarrierPostMatches-${truckPostId}`;
  }

  refreshMatch(posting: TruckPost): Observable<TruckMatch[]> {
    var cacheId = this.cacheId(posting.truckPostingID);
    localStorage.removeItem(cacheId);
    return this.match(posting);
  }

  match(posting: TruckPost): Observable<TruckMatch[]> {
    const promise = new Observable<TruckMatch[]>((subscriber) => {
      var cacheId = this.cacheId(posting.truckPostingID);
      var cachedMatches = localStorage.getItem(cacheId);

      var matches: TruckMatch[] = [];

      // call service to get match
      const critera = {
        originLatitude: posting.origin.latitude,
        originLongitude: posting.origin.longitude,
        originRadius: posting.originDeadheadMiles,
        destinationLatitude: posting.destination.latitude,
        destinationLongitude: posting.destination.longitude,
        destinationRadius: posting.destinationDeadheadMiles,
        dateAvailable: posting.emptyDateTime,
        truckType: posting.truckType,
      } as ShipmentSearchCriteriaModel;

      this.matchService.match(critera).subscribe((response) => {
        response.forEach((truckMatch) => {
          var matchTransform = {
            truckPostingID: posting.truckPostingID,
            shipmentID: truckMatch.shipmentID,
            primaryReference: truckMatch.primaryReference,
            readyDate: truckMatch.earliestPickup,
            truckOrigin: {
              city: posting.origin.city,
              state: posting.origin.stateProvince,
              latitude: posting.origin.latitude,
              longitude: posting.origin.longitude,
            },
            truckDestination: {
              city: posting.destination.city,
              state: posting.destination.stateProvince,
              latitude: posting.destination.latitude,
              longitude: posting.destination.longitude,
            },
            loadOrigin: {
              city: truckMatch.originCity,
              state: truckMatch.originState,
              deadHead: truckMatch.originDeadHead,
              earliest: truckMatch.earliestPickup,
              latest: truckMatch.latestPickup,
            },
            loadDestination: {
              city: truckMatch.destinationCity,
              state: truckMatch.destinationState,
              deadHead: truckMatch.destinationDeadHead,
              earliest: truckMatch.earliestDelivery,
              latest: truckMatch.latestDelivery,
            },
            loadMiles: truckMatch.distance,
            truckType: critera.truckType,
            weight: truckMatch.weight,
            buyRate: truckMatch.carrierRate,
            sellRate: truckMatch.customerRate,
            carrierRateCurrencyCode: truckMatch.carrierRateCurrencyCode,
            customerRateCurrencyCode: truckMatch.customerRateCurrencyCode,
          } as TruckMatch;

          matches.push(matchTransform);
        });

        subscriber.next(matches);
      });
    });

    return promise;
  }

  update(post: TruckPost): Observable<TruckMatch[]> {
    return this.match(post);
  }

  delete(post: TruckPost) {
    var cacheId = this.cacheId(post.truckPostingID);
    localStorage.removeItem(cacheId);
  }

  getCarrierSummary(carrierCode: string): Observable<CarrierSummaryDC> {
    return this.matchService.getCarrierSummary(carrierCode);
  }
}
