
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { parseDate } from '@telerik/kendo-intl';
import { Store } from '@ngrx/store';
import { CarrierSearchResult } from '../models/CarrierSearchResults';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class IdentitySearchService {
  config: Configuration;
  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getByIdentifier(carrierIdentifier: string): Observable<CarrierSearchResult[]> {
    const url = `${this.config.carrierServiceUrl}search/${carrierIdentifier}`;

    return this.httpClient.get<CarrierSearchResult[]>(url).pipe(map((x) => {
      x.map((c) => {
        c.lastContacted = parseDate('' + c.lastContacted);
        if (c.stateCarrierID) {
          c.stateCarrierID = `${c.stateCode} ${c.stateCarrierID}`;
        } else {
          c.stateCarrierID = '';
        }
      });
      return x;
    }));
  }
}
