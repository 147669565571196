import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { TrackingPreference } from '../models/TrackingPreference';
import { CarrierEdit } from '../models/CarrierEdit';
import { TrackingPreferenceService } from './tracking-preference.service';

// TODO: Move all carrier data in here and use ngrx
@Injectable()
export class CarrierEditService {
  carrierChangedSubject: BehaviorSubject<CarrierEdit>;
  carrierCode: string;
  carrier: CarrierEdit = new CarrierEdit();

  constructor(private trackingPreferenceService: TrackingPreferenceService) {
    this.carrierChangedSubject = new BehaviorSubject<CarrierEdit>(this.carrier);
  }

  setup(carrierCode: string) {
    this.carrierCode = carrierCode;
    this.carrier = new CarrierEdit();
    const refTrackingPreferences$ = this.trackingPreferenceService.getRefTrackingPreferences();
    const trackingPreferences$ = this.trackingPreferenceService.get(this.carrierCode);
    forkJoin([trackingPreferences$, refTrackingPreferences$]).subscribe(([trackingPreferences, refTrackingPreferences]) => {
      this.carrier.refTrackingPreferences = refTrackingPreferences;
      if (trackingPreferences != null) {
        this.carrier.trackingPreference = trackingPreferences;
      } else {
        this.carrier.trackingPreference = {
          method: 'Trucker Tools',
          trackingPreferenceID: 0,
        };
      }
      this.updateCarrier();
    });
  }

  savePreference(trackingPreference: any): Observable<TrackingPreference> {
    return new Observable<TrackingPreference>((observer) => {
      this.trackingPreferenceService.save(this.carrierCode, trackingPreference).subscribe(
        (x) => {
          this.carrier.trackingPreference = x;
          this.updateCarrier();
          observer.next(x);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  updateCarrier() {
    this.carrierChangedSubject.next(this.carrier);
  }
}
