export const TenderValidationTypes = {
  Error: 'Error',
  Warning: 'Warning',
} as const;

export type TenderValidationResponse = {
  isValid: boolean;
  validations: TenderValidation[];
};

export type TenderValidation = {
  type: (typeof TenderValidationTypes)[keyof typeof TenderValidationTypes];
  message: string;
};

export type ErrorTenderValidation = TenderValidation & {
  type: (typeof TenderValidationTypes)['Error'];
};

export type WarningTenderValidation = TenderValidation & {
  type: (typeof TenderValidationTypes)['Warning'];
};
