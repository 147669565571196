import { Component, OnInit } from '@angular/core';
import { CarrierTruckpostingSearchResult } from '../../models/CarrierTruckpostingSearchResults';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import * as SearchActions from '../../actions/search.actions';
import { PageChangeEvent } from '@progress/kendo-angular-grid';


@Component({
  selector: 'truckload-carrier-truckposting-grid',
  templateUrl: './carrier-truckposting-grid.component.html',
  styleUrls: ['./carrier-truckposting-grid.component.scss']
})
export class CarrierTruckpostingGridComponent {
  results: CarrierTruckpostingSearchResult[];
  sort: SortDescriptor[] = [];
  gridState: PageChangeEvent = { skip: 0, take: 25 };
  canViewPostMatch: boolean;
  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Search.carrierTruckpostingSearchResults)
      .subscribe((x) => {
        this.results = x;
      });

    this.store
      .select((x) => x.Search.carrierTruckpostingGridSort)
      .subscribe((x) => {
        this.sort = x;
      });
    this.store
      .select((x) => x.Search.canViewPostMatch)
      .subscribe((x) => {
        this.canViewPostMatch = x;
      });

    this.store
      .select((x) => x.Search.carrierTruckpostingGridPaging)
      .subscribe((x) => {
        if (x) {
          this.gridState = x;
        }
      });
  }

  pageChange(paging: PageChangeEvent): void {
    this.store.dispatch(new SearchActions.CarrierTruckpostingGridPaging(paging));
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.store.dispatch(new SearchActions.CarrierTruckpostingGridSort(sort));
  }
}
