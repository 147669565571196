import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { User, Template } from './User';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class UserService {
  config: Configuration;
  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getSearch(value: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.config.carrierServiceUrl}appuser/search?filter=${value}`);
  }

  /** Gets an AppUser, by appUserName. */
  getByName(appUserName: string): Observable<User> {
    return this.httpClient.get<User>(`${this.config.carrierServiceUrl}appuser?username=${appUserName}`);
  }

  getAllTemplates(): Observable<Template[]> {
    return this.httpClient.get<Template[]>(`${this.config.carrierServiceUrl}template`);
  }

  save(user: User): Observable<User> {
    return this.httpClient.put<User>(`${this.config.carrierServiceUrl}appuser`, user);
  }

  create(user: User): Observable<User> {
    return this.httpClient.post<User>(`${this.config.carrierServiceUrl}appuser`, user);
  }

  delete(userName: string): Observable<any> {
    var promise = new Observable<any>((s) => {
      this.httpClient.delete(`${this.config.carrierServiceUrl}appuser?userName=${userName}`).subscribe(
        () => {
          s.next(false);
        },
        (e) => {
          s.next(e);
        }
      );
    });
    return promise;
  }

  // get user, set disabled to false, save
  enable(userName: string): Observable<User> {
    var promise = new Observable<User>((s) => {
      var userObs = this.getByName(userName);

      userObs.subscribe((x) => {
        x.isDisabled = false;
        this.save(x).subscribe(
          () => {
            s.next(x);
          },
          (e) => {
            s.error(e);
          }
        );
      });
    });

    return promise;
  }
}
