import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { filter } from 'rxjs/operators';
import { AppState } from '../../../appstate.model';
import { AuthService } from '../../../authentication/_services/auth.service';
import { BGStatusType } from '../../../shared/Enums/BGStatusType';
import { ManuallyMonitoredType } from '../../../shared/Enums/ManuallyMonitoredType';
import { Permissions } from '../../../shared/Enums/Permissions';
import { FeatureFlagService } from '../../../shared/feature-flag/feature-flag.service';
import { UpdateCarrierStatus } from '../../actions/carrier-details.actions';
import { CarrierFileDetails } from '../../models/CarrierFileDetails';
import { CarrierStatus } from '../../models/CarrierStatus';

@Component({
  selector: 'truckload-carrier-active-status',
  templateUrl: './carrier-active-status.component.html',
  styleUrls: ['./carrier-active-status.component.scss'],
})
export class CarrierActiveStatusComponent implements OnInit {
  bgStatusList = Object.values(BGStatusType);
  manuallyMonitoredList = Object.values(ManuallyMonitoredType);
  carrierTiersList = [1, 2, 3];
  loading: boolean;
  hideButtons: boolean;
  carrierFile: CarrierFileDetails;
  carrierStatus: CarrierStatus;
  showLoading: boolean;
  triggerFromDropDown: boolean;

  editForm: FormGroup;
  editTruckerToolForm: FormGroup;
  enableTruckerTools: boolean;
  _subscription: any;
  flags: LDFlagSet;

  newFormIsTruckerToolsQualified: boolean;
  newFormIsAuthorizedToQuickBook: boolean;
  newFormStatusValue: string;
  newFormIsManualMonitored: boolean;
  newFormCarrierTier: any;

  updatedValueOnChangeIsTruckerToolsQualified: boolean;
  updatedValueOnChangeIsAuthorizedToQuickBook: boolean;
  updatedValueOnChangeStatusValue: string;
  updatedValueOnChangeIsManualMonitored: boolean;
  updatedValueOnChangeCarrierTier: any;

  constructor(private authService: AuthService, private store: Store<AppState>, private ld: FeatureFlagService) {}

  ngOnInit() {
    this.setListeners();
  }

  private setListeners() {
    this.store
      .select((x) => x.FeatureFlags)
      .subscribe((y) => {
        this.flags = y.flags;

        if (this.flags['orca-trucker-tools'] !== undefined && this.flags['orca-trucker-tools'].current !== undefined) {
          this.flags['orca-trucker-tools'] = this.flags['orca-trucker-tools'].current;
        }
        this.enableTruckerTools = this.flags && this.flags['orca-trucker-tools'] ? this.flags['orca-trucker-tools'] : false;
      });

    this.store
      .select((x) => x.CarrierDetails.loading)
      .subscribe((x) => {
        this.loading = x;
        this.showLoading = x;
      });

    this.store
      .pipe(
        select((x) => x.CarrierDetails.details),
        filter((details) => details != null)
      )
      .subscribe((x) => {
        this.carrierFile = x;
        this.setNewFormStateVariables(
          this.carrierFile.isTruckerToolsQualified,
          this.carrierFile.isAuthorizedToQuickBook,
          this.carrierFile.status,
          this.carrierFile.isManual,
          this.carrierFile.carrierTier
        );
      });

    this.store
      .select((x) => x.CarrierDetails.status)
      .subscribe((x) => {
        this.showLoading = false;
        this.carrierStatus = x;

        if (!this.editForm && this.carrierStatus) {
          this.editForm = new FormGroup({
            status: new FormControl(this.carrierStatus.status, Validators.required),
            isManuallyMonitored: new FormControl(
              this.getManuallyMonitoredStatus(this.carrierStatus.isManuallyMonitored),
              Validators.required
            ),
            isTruckerToolsQualified: new FormControl(this.carrierFile.isTruckerToolsQualified),
            isAuthorizedToQuickBook: new FormControl(this.carrierFile.isAuthorizedToQuickBook),
            carrierTier: new FormControl(this.carrierFile.carrierTier),
          });
        } else if (this.editForm) {
          this.cancel();
        }
      });
  }

  setNewFormStateVariables(
    isTruckerToolsQualified: boolean,
    isAuthorizedToQuickBook: boolean,
    status: string,
    isManualMonitored: boolean,
    carrierTier: number
  ): void {
    this.newFormIsTruckerToolsQualified = isTruckerToolsQualified;
    this.newFormIsAuthorizedToQuickBook = isAuthorizedToQuickBook;
    this.newFormStatusValue = status;
    this.newFormIsManualMonitored = isManualMonitored;
    this.newFormCarrierTier = carrierTier;

    this.updatedValueOnChangeIsTruckerToolsQualified = isTruckerToolsQualified;
    this.updatedValueOnChangeIsAuthorizedToQuickBook = isAuthorizedToQuickBook;
    this.updatedValueOnChangeStatusValue = status;
    this.updatedValueOnChangeIsManualMonitored = isManualMonitored;
    this.updatedValueOnChangeCarrierTier = carrierTier;
  }

  verifyFirstState(): void {
    if (
      this.isTruckerToolsQualified() != this.newFormIsTruckerToolsQualified ||
      this.isAuthorizedToQuickBook() != this.newFormIsAuthorizedToQuickBook ||
      this.updatedValueOnChangeIsManualMonitored != this.newFormIsManualMonitored ||
      this.updatedValueOnChangeStatusValue.toLowerCase().replace(/\s/g, '') != this.newFormStatusValue.toLowerCase().replace(/\s/g, '') ||
      this.updatedValueOnChangeCarrierTier != this.newFormCarrierTier
    )
      this.hideButtons = false;
    else this.hideButtons = true;
  }

  statusSelectionChange(value: string): void {
    this.updatedValueOnChangeStatusValue = value.toLowerCase().replace(/\s/g, '');
    this.carrierStatus.status = value.toLowerCase().replace(/\s/g, '');
    this.verifyFirstState();
  }

  isManuallyMonitoredSelectionChange(value: string): void {
    this.updatedValueOnChangeIsManualMonitored = value == 'Yes';
    this.verifyFirstState();
  }

  carrierTierSelectionChange(value: string): void {
    this.updatedValueOnChangeCarrierTier = value;
    this.verifyFirstState();
  }

  get canEditBGStatus(): boolean {
    return this.authService.can(Permissions.EditBGStatus);
  }

  get canEditTruckerTools(): boolean {
    return this.authService.can(Permissions.EditTruckerTools);
  }

  get canEditManuallyMonitored(): boolean {
    return this.authService.can(Permissions.EditManuallyMonitored);
  }

  getManuallyMonitoredStatus(isManuallyMonitored: boolean): string {
    return isManuallyMonitored ? 'Yes' : 'No';
  }

  isManuallyMonitored(): boolean {
    return this.editForm.value.isManuallyMonitored === 'Yes';
  }

  isTruckerToolsQualified(): boolean {
    return this.editForm.value.isTruckerToolsQualified;
  }

  isAuthorizedToQuickBook(): boolean {
    return this.editForm.value.isAuthorizedToQuickBook;
  }

  submit(): void {
    this.showLoading = true;
    const newStatus = {
      status: this.editForm.value.status,
      isManuallyMonitored: this.isManuallyMonitored(),
      isTruckerToolsQualified: this.isTruckerToolsQualified(),
      isAuthorizedToQuickBook: this.isAuthorizedToQuickBook(),
      carrierTier: this.editForm.value.carrierTier ? this.editForm.value.carrierTier : 3,
    } as CarrierStatus;

    this.setNewFormStateVariables(
      newStatus.isTruckerToolsQualified,
      newStatus.isAuthorizedToQuickBook,
      newStatus.status,
      newStatus.isManuallyMonitored,
      newStatus.carrierTier
    );

    this.store.dispatch(new UpdateCarrierStatus(this.carrierFile.carrierCode, newStatus));
  }

  cancel() {
    this.editForm.reset({
      status: this.carrierStatus.status,
      isManuallyMonitored: this.getManuallyMonitoredStatus(this.carrierStatus.isManuallyMonitored),
      isTruckerToolsQualified: !this.carrierStatus.isTruckerToolsQualified
        ? this.carrierFile.isTruckerToolsQualified
        : this.carrierStatus.isTruckerToolsQualified,
      isAuthorizedToQuickBook: !this.carrierStatus.isAuthorizedToQuickBook
        ? this.carrierFile.isAuthorizedToQuickBook
        : this.carrierStatus.isAuthorizedToQuickBook,
      carrierTier: !this.carrierStatus.carrierTier ? this.carrierFile.carrierTier : this.carrierStatus.carrierTier,
    });
  }

  toggleTruckerToolsQualified() {
    if (!this.isTruckerToolsQualified()) {
      this.editForm.patchValue({ isAuthorizedToQuickBook: false });
    }
    this.verifyFirstState();
  }

  toggleCanQuickBook() {
    this.verifyFirstState();
  }

  initTruckToolsForm() {
    if (this.editTruckerToolForm == null) {
      this.editTruckerToolForm = new FormGroup({
        isTruckerToolsQualified: new FormControl(this.carrierFile.isTruckerToolsQualified, Validators.required),
        isAuthorizedToQuickBook: new FormControl(this.carrierFile.isAuthorizedToQuickBook, Validators.required),
      });
    }
  }
}
