import { Component, OnInit, Input, ViewChild, ViewContainerRef, TemplateRef, EventEmitter, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { Template } from '../Template';
import { TemplatesService } from '../templates.service';
import { AdminService } from '../../admin.service';
import { Permission } from '../../Admin';
@Component({
  selector: 'admin-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss'],
})
export class TemplateEditComponent implements OnInit {
  @ViewChild('permList', { static: true })
  permList;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;

  @ViewChild('errorContainer', { read: ViewContainerRef, static: true })
  public errorContainer: ViewContainerRef;

  @Input()
  template: Template;

  @Input()
  buttonLabel: string;

  @Input()
  title: string;

  @Input()
  isNew: boolean;

  @Output()
  templateUpdated = new EventEmitter<Template[]>();

  editForm: FormGroup;
  editDialog: DialogRef;
  allPerms: Permission[];

  public loading: boolean = false;
  public loadingIcon: string = '';

  ngOnInit(): void {}

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private templateService: TemplatesService,
    private adminService: AdminService
  ) {}

  private setLoading(state: boolean) {
    this.loading = state;
    this.loadingIcon = state ? 'loading' : '';
  }

  get canManageUserPermissions(): boolean {
    return this.authService.can(Permissions.ManageUserPermissions);
  }

  private showEdit(contentTemplate: TemplateRef<any>, actionsTemplate: TemplateRef<any>) {
    this.setLoading(true);
    this.adminService.getAllPermissions().subscribe((results) => {
      this.allPerms = results;
      this.initForm(this.template, contentTemplate, actionsTemplate);
      this.setLoading(false);
    });
  }

  private initForm(template: Template, contentTemplate: TemplateRef<any>, actionsTemplate: TemplateRef<any>) {
    if (this.isNew) {
      template = {
        description: '',
        name: '',
        templateID: 0,
        permissions: [],
      } as Template;
    } else {
      if (template.permissions == null) template.permissions = [];
    }

    this.createForm(template, this.title, contentTemplate, actionsTemplate);
  }

  private createForm(template: Template, title: string, contentTemplate: TemplateRef<any>, actionsTemplate: TemplateRef<any>) {
    this.editForm = new FormGroup({
      templateID: new FormControl(template.templateID),
      name: new FormControl(template.name, [Validators.required]),
      description: new FormControl(template.description, [Validators.required]),
      permissions: new FormControl(template.permissions),
    });
    this.editDialog = this.dialogService.open({
      title: title,
      content: contentTemplate,
      actions: actionsTemplate,
      width: 1200,
      height: 900,
    });
  }

  public save() {
    this.setLoading(true);
    const template = Object.assign({}, this.editForm.value) as Template;
    this.templateService.update(template).subscribe(
      (newTemplates) => {
        this.setLoading(false);
        this.showSuccessNotification(`Saved template ${template.name}`);
        this.editDialog.close();
        this.templateUpdated.emit(newTemplates);
      },
      (e) => {
        this.setLoading(false);
        this.showErrorNotification(`${e.message}`);
      }
    );
  }

  public cancel() {
    this.editDialog.close();
  }

  public isItemSelected(itemText: string): boolean {
    if (itemText == undefined) return false;

    return this.editForm.value.permissions.some((item) => item.name === itemText);
  }

  // Notifications
  public showErrorNotification(msg: string): void {
    this.notificationService.show({
      content: `${msg}`,
      appendTo: this.errorContainer,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'info', icon: true },
    });
  }

  public showSuccessNotification(msg: string): void {
    this.notificationService.show({
      content: `${msg}`,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'info', icon: true },
    });
  }
}
