import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(val, args) {
    if (!val) {
      return '';
    }

    let viewVal = val.trim().replace(/^\+/, '');
    viewVal = viewVal.replace(/[^0-9]/g, '').slice(0, 10);

    const area = viewVal.slice(0, 3);
    let number = viewVal.slice(3);

    number = number.slice(0, 3) + '-' + number.slice(3);

    return ('(' + area + ') ' + number).trim();
  }
}
