import { CarrierSearchResult } from './CarrierSearchResults';
import { CarrierLaneSearchResult } from './CarrierLaneSearchResults';

import { CarrierSearchParameters } from './CarrierSearchParameters';
import { CarrierNameSearchParamters } from './CarrierNameSearchParamters';
import { CarrierIdentitySearchParameters } from './CarrierIdentitySearchParameters';
import { CarrierLocationSearchParameters } from './CarrierLocationSearchParameters';
import { CarrierLaneSearchParameters } from './CarrierLaneSearchParameters';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CarrierTruckPostingSearchParameters } from './CarrierTruckPostingSearchParameters';
import { CarrierTruckpostingSearchResult } from './CarrierTruckpostingSearchResults';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

export class SearchState {
  // loading indicators
  loading: boolean;
  loadingError: string;
  loadingSearch: boolean;
  loadingSearchError: string;

  carrierGridSort: SortDescriptor[];
  carrierGridPaging: PageChangeEvent;

  carrierLaneGridSort: SortDescriptor[];
  carrierLaneGridPaging: PageChangeEvent;

  carrierTruckpostingGridSort: SortDescriptor[];
  carrierTruckpostingGridPaging: PageChangeEvent;

  // permissions
  canSearchByID: boolean;
  canSearchByName: boolean;
  canSearchByLocation: boolean;
  canSearchByLane: boolean;
  canViewPostMatch: boolean;
  canCreateManualCarrier: boolean;
  canSearchByTruckposting: boolean;

  // show/hide different searches
  showNameSearch: boolean;
  showIDSearch: boolean;
  showLocationSearch: boolean;
  showLaneSearch: boolean;
  showTruckpostingSearch: boolean;

  // show/hide different grids
  showCarrierGrid: boolean;
  showLaneGrid: boolean;
  showTruckpostingGrid: boolean;

  // ref data
  truckTypes: string[];
  serviceTypes: string[];
  certificationTypes: string[];

  // view models for search form parameters
  carrierSearchParams: CarrierSearchParameters;
  carrierNameSearchParams: CarrierNameSearchParamters;
  carrierIdentitySearchParams: CarrierIdentitySearchParameters;
  carrierLocationSearchParams: CarrierLocationSearchParameters;
  carrierLaneSearchParams: CarrierLaneSearchParameters;
  carrierTruckpostingSearchParams: CarrierTruckPostingSearchParameters;

  // result view models for different grids
  carrierSearchResults: CarrierSearchResult[];
  carrierLaneSearchResults: CarrierLaneSearchResult[];
  carrierTruckpostingSearchResults: CarrierTruckpostingSearchResult[];
}
