import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../Enums/Permissions';

@Injectable()
export class LaneLoadHistoryGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): boolean {
    var result = this.authService.can(Permissions.ViewLaneLoadHistory);

    if (result) {
      return true;
    }

    this.authService.redirectNotAuthorized();
    return false;
  }
}
