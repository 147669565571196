import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
export abstract class Submitable extends DialogContentBase {
  constructor(dialog: DialogRef) {
    super(dialog);
  }
  @ViewChild('submitButton', { static: true }) button;

  disableAndSubmitButton(nameButton?: string): void {
    if (this.button) {
      this.button.nativeElement.disabled = true;
      let assignNameButton = nameButton ? nameButton : 'Submitting...'
      this.button.nativeElement.innerHTML = `${assignNameButton} <i class="fas fa-spin fa-spinner"></i>`;
    }
  }

  resetButton(nameButton?: string): void {
    if (this.button) {
      this.button.nativeElement.disabled = false;
      let assignNameButton = nameButton ? nameButton : 'Submit'
      this.button.nativeElement.innerHTML = assignNameButton;
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(`Something bad happened at ${error.url}; please try again later.  body was: ${error.error}`);
  }
}
