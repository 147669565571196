import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { UserService } from './users.service';
import { User } from './User';
import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'admin-users',
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
  @ViewChild('userGrid', { static: true })
  userGrid: GridComponent;
  userData: User[];
  enableDisableAppUser: User;
  disableEditAction = false;
  public dialog: DialogRef;

  ngOnInit(): void {
    this.userSearch('');
  }

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  onKey(value: string) {
    this.userSearch(value);
  }

  private userSearch(filter: string) {
    this.userService.getSearch(filter).subscribe((x) => {
      this.userData = x;
    });
  }

  get canManageUsers(): boolean {
    return this.authService.can(Permissions.ManageUsers);
  }

  get canViewUsers(): boolean {
    return (
      this.authService.can(Permissions.ViewUsers) || this.canViewPermissions || this.canManageUserPermissions || this.canManagePermissions
    );
  }

  get canViewPermissions(): boolean {
    return this.authService.can(Permissions.ViewPermissions);
  }

  get canManageUserPermissions(): boolean {
    return this.authService.can(Permissions.ManageUserPermissions);
  }

  get canManagePermissions(): boolean {
    return this.authService.can(Permissions.ManagePermissions);
  }

  canEditUser(user: User): boolean {
    if (this.authService.loggedAppUser.appUserName == user.appUserName) {
      return this.canManagePermissions;
    } else {
      return this.canManageUserPermissions;
    }
  }

  public showConfirm(appUser: User, title: string, bodyTemplate: TemplateRef<any>) {
    this.enableDisableAppUser = appUser;
    this.dialog = this.dialogService.open({
      width: 500,
      height: 320,
      title: title,
      content: bodyTemplate,
    });
  }

  public disableConfirm() {
    this.userService.delete(this.enableDisableAppUser.appUserName).subscribe(
      () => {
        this.enableDisableAppUser.isDisabled = true;
        this.userData = this.userData.filter(x => x.appUserName !== this.enableDisableAppUser.appUserName);
        this.dialog.close();
        this.showAppUserSuccessNotification(`Disabled user ${this.enableDisableAppUser.appUserName}`);
      },
      (e) => {
        this.dialog.close();
        this.showAppUserErrorNotification(`Error Disabling user ${this.enableDisableAppUser.appUserName}: ${e.message}`);
      },
      () => {
        this.dialog.close();
      }
    );
  }

  public enableConfirm() {
    this.userService.enable(this.enableDisableAppUser.appUserName).subscribe(
      () => {
        this.enableDisableAppUser.isDisabled = false;
        this.dialog.close();
        this.showAppUserSuccessNotification(`Enabled user ${this.enableDisableAppUser.appUserName}`);
      },
      (e) => {
        this.dialog.close();
        this.showAppUserErrorNotification(`Error Enabling user ${this.enableDisableAppUser.appUserName}: ${e.message}`);
      },
      () => {
        this.dialog.close();
      }
    );
  }

  public cancel() {
    this.dialog.close();
  }

  public showAppUserSuccessNotification(msg: string): void {
    this.notificationService.show({
      content: msg,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: {
        horizontal: 'right',
        vertical: 'top',
      },
      type: { style: 'info', icon: true },
    });
  }

  public showAppUserErrorNotification(msg: string): void {
    this.notificationService.show({
      content: msg,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'error', icon: true },
    });
  }

  public userUpdated(user: User) {
    var existing = this.userData.find((x) => x.appUserID == user.appUserID);
    if (existing == undefined) {
      this.userData.push(user);
      this.userGrid.data = this.userData;
      this.showAppUserSuccessNotification(`User ${user.appUserName} created successfully`);
    } else {
      this.showAppUserSuccessNotification(`User ${user.appUserName} updated successfully`);
    }
  }
}
