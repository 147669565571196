import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CarrierOfferDC } from './CarrierOfferDC';
import { CarrierProfileOfferService } from './carrier-profile-offer.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { CarrierSummaryDC } from './/CarrierSummaryDC';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CarrierOfferVM } from './CarrierOfferVM';
import { CarrierOfferService } from '../../../shared/carrier-offer/carrier-offer.service';
import * as moment from 'moment';
import { CurrencyService } from '../../../Services/currency.service';
import { UserDetails } from '../../../shared/carrier-offer/UserDetails';

@Component({
  selector: 'truckload-carrier-profile-offer',
  templateUrl: './carrier-profile-offer.component.html',
  styleUrls: ['./carrier-profile-offer.component.scss']
})
export class CarrierProfileOfferComponent implements OnInit {
  @Input()
  carrierSummary: CarrierSummaryDC;

  private dialog: DialogRef;

  // reactive form for offer
  formCarrierOffer: FormGroup;

  private userDetails: UserDetails;
  public rateConversion = 1;
  showCurrency = false;
  currencyCode = '';

  ngOnInit(): void {
    this.carrierProfileOffer.getUserDetails().subscribe(x => {
      this.userDetails = {
        enterpriseID: x.enterpriseID,
        userID: x.userID,
        userName: x.userName
      };
    });
  }

  get buyRate() {
    return this.formCarrierOffer.get('buyRate');
  }
  get quoteNote() {
    return this.formCarrierOffer.get('quoteNote');
  }
  get shipmentReference() {
    return this.formCarrierOffer.get('shipmentReference');
  }
  get convertedBuyRate() {
    return this.buyRate.value * this.rateConversion;
  }

  constructor(
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private carrierProfileOffer: CarrierProfileOfferService,
    private carrierOfferService: CarrierOfferService,
    private currencyService: CurrencyService
  ) { }

  public showOffer(actionTemplate: TemplateRef<any>, bodyTemplate: TemplateRef<any>) {
    this.createForm(this.carrierSummary);
    this.dialog = this.dialogService.open({
      width: 800,
      height: 720,
      title: 'Create Carrier Offer',
      content: bodyTemplate,
      actions: actionTemplate
    });
  }

  private createOffer() {
    var shipRef = this.formCarrierOffer.value.shipmentReference;

    this.carrierProfileOffer.findShipment(shipRef, this.userDetails).subscribe(
      x => {
        if (x == null) {
          this.shipmentReference.setErrors({ notFound: true });
        } else {
          this.requestNewOffer(x.shipmentID);
        }
      },
      err => {
        this.shipmentReference.setErrors({ notFound: true });
      }
    );
  }

  private requestNewOffer(shipmentID: number) {
    const offer = Object.assign({}, this.formCarrierOffer.value) as CarrierOfferVM;
    const offerDC = {
      carrierCode: offer.carrierCode,
      carrierName: offer.carrierName,
      dotNumber: offer.dotNumber,
      mcNumber: offer.mcNumber,
      scac: offer.scac,
      currencyCode: (this.showCurrency) ? 'CAD' : 'USD',
      carrierChargeInUSD: offer.buyRate,
      carrierCharge: (this.showCurrency) ? this.convertedBuyRate : offer.buyRate,
      customerCharge: 0,
      notes: offer.quoteNote
    } as CarrierOfferDC;

    this.dialog.close();
    this.showCurrency = false;

    this.carrierProfileOffer.createOffer(shipmentID, this.userDetails, offerDC).subscribe(
      res => {
        this.showOfferCreatedNotification(offerDC);
      },
      err => {
        this.showOfferErrorNotification(err);
      }
    );
  }

  private cancelOffer() {
    this.showCurrency = false;
    this.dialog.close();
  }

  shipmentReferenceUpdated($event) {
    console.log('shipment reference', $event);
    const shipRef = $event.target.value;
    if ($event.target.value) {
      this.carrierProfileOffer.findShipment(shipRef, this.userDetails).subscribe(
        x => {
          if (x !== null) {
            this.carrierOfferService.getActiveShipment(x.shipmentID)
              .subscribe((res) => {
                if (res !== null) {
                  this.getCurrencyRate(res.refCountryCode, res.refCurrencyCode);
                }

              });
          }
        }
      );
    }

  }

  async getCurrencyRate(countryCode: string, currencyCode: string) {
    const effectiveDate = moment(new Date()).format('L');
    (await this.currencyService.getCurrency(effectiveDate.toString()))
      .subscribe((x) => {
        if (countryCode && countryCode.toLocaleLowerCase() !== 'usa') {
          const rate = x.find(c => c.country.toLocaleLowerCase() === countryCode.toLocaleLowerCase());
          this.rateConversion = rate ? rate.value : 1;
          this.currencyCode = currencyCode;
          this.showCurrency = true;
        }
      });
  }

  public showOfferCreatedNotification(offer: CarrierOfferDC): void {
    this.notificationService.show({
      content: 'Offer has been created for ' + offer.carrierName,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 3000 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'info', icon: true }
    });
  }

  public showOfferErrorNotification(err: any): void {
    this.notificationService.show({
      content: `Error creating offer: ${err.message}`,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 3000 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'error', icon: true }
    });
  }

  private createForm(carrierSummary: CarrierSummaryDC) {
    this.formCarrierOffer = new FormGroup({
      shipmentReference: new FormControl(null, [Validators.required]),
      shipmentID: new FormControl(1, [Validators.required]),
      carrierName: new FormControl(carrierSummary.name),
      carrierCode: new FormControl(carrierSummary.carrierCode, [Validators.required]),
      mcNumber: new FormControl(carrierSummary.mcNumber),
      dotNumber: new FormControl(carrierSummary.dotNumber),
      scac: new FormControl(carrierSummary.scac),
      buyRate: new FormControl(null, [Validators.required]),
      quoteNote: new FormControl(null, [Validators.required])
    });
  }
}
