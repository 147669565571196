import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TruckPostingDefaults } from './TruckPostingDefaults';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class TruckPostingDefaultsService {
  config: Configuration;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }
  public getCarrierPostDefaults(carrierCode: string): Observable<TruckPostingDefaults> {
    return this.http.get<TruckPostingDefaults>(`${this.config.carrierServiceUrl}${carrierCode}/truckpostingdefaults`).pipe(
      map((p: TruckPostingDefaults) => {
        return this.mapTruckPosting(p);
      })
    );
  }
  public save(carrierCode: string, truckPostingDefaults: TruckPostingDefaults): Observable<TruckPostingDefaults> {
    var command = {
      truckLengthValue: truckPostingDefaults.truckLengthValue,
      truckLengthUom: truckPostingDefaults.truckLengthUom,
      contactName: truckPostingDefaults.contactName,
      truckTypeCode: truckPostingDefaults.truckTypeCode,
      originDeadheadMiles: truckPostingDefaults.originDeadheadMiles,
      destinationDeadheadMiles: truckPostingDefaults.destinationDeadheadMiles,
      originRefApiLocation: truckPostingDefaults.origin ? truckPostingDefaults.origin.refAPILocationID : null,
      destinationRefApiLocation: truckPostingDefaults.destination ? truckPostingDefaults.destination.refAPILocationID : null,
    };
    return this.http.post<TruckPostingDefaults>(`${this.config.carrierServiceUrl}${carrierCode}/truckpostingdefaults`, command).pipe(
      map((p: TruckPostingDefaults) => {
        return this.mapTruckPosting(p);
      })
    );
  }

  private mapTruckPosting(p: TruckPostingDefaults): TruckPostingDefaults {
    if (p) {
      if (p.origin) {
        p.origin.cityState = `${p.origin.city}, ${p.origin.stateProvince}`;
      }
      if (p.destination) {
        p.destination.cityState = `${p.destination.city}, ${p.destination.stateProvince}`;
      }
    } else {
      p = new TruckPostingDefaults();
    }
    return p;
  }
}
