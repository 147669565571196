export interface TruckPostAddress {
  carrierTruckPostingLocationID: 0;
  refAPILocationID: number;
  type: string;
  city: string;
  stateProvince: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  cityState: string;
}

export class TruckPost {
  truckPostingID: number;
  truckLengthValue: number;
  truckLengthUom: string;
  truckWidthValue: number;
  truckWidthUom: string;
  truckHeightValue: number;
  truckHeightUom: string;
  emptyDateTime: Date;
  contactName: string;
  note: string;
  isTeam: boolean;
  truckType: string;
  truckTypeCode: string;
  originDeadheadMiles: number;
  destinationDeadheadMiles: number;
  userCreated: string;
  dateCreated: Date;
  activityID: number;
  activityType: string;
  dispositionType: string;
  origin: TruckPostAddress;
  destination: TruckPostAddress;
}
