import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import * as SearchActions from '../actions/search.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SearchService } from '../services/search.service';
import { NameSearchService } from '../services/name-search.service';
import { IdentitySearchService } from '../services/identity.service';
import { LocationSearchService } from '../services/location-search.service';
import { CarrierLaneSearchService } from '../services/carrier-lane-search.service';
import { CarrierTruckpostingSearchService } from '../services/carrier-truckposting-search.service';

@Injectable()
export class SearchEffects {
  @Effect()
  loadMyMatches$ = this.actions$.pipe(
    ofType(...[SearchActions.SEARCH_LOAD]),
    mergeMap(() =>
      this.searchService.getState().pipe(
        map((state) => {
          return new SearchActions.SearchLoadSuccess(state);
        }),
        catchError((x) => {
          return of(new SearchActions.SearchLoadError(x.error));
        })
      )
    )
  );

  @Effect()
  searchName$ = this.actions$.pipe(
    ofType(...[SearchActions.SEARCH_NAME]),
    mergeMap((action: SearchActions.SearchName) =>
      this.nameSearchService.getByName(action.params.CarrierName).pipe(
        map((results) => {
          return new SearchActions.SearchCarrierSuccess(results);
        }),
        catchError((x) => {
          return of(new SearchActions.SearchCarrierError(x.error));
        })
      )
    )
  );

  @Effect()
  searchId$ = this.actions$.pipe(
    ofType(...[SearchActions.SEARCH_ID]),
    mergeMap((action: SearchActions.SearchID) =>
      this.identitySearchService.getByIdentifier(action.params.CarrierIdentification).pipe(
        map((results) => {
          return new SearchActions.SearchCarrierSuccess(results);
        }),
        catchError((x) => {
          return of(new SearchActions.SearchCarrierError(x.error));
        })
      )
    )
  );

  @Effect()
  searchLocation$ = this.actions$.pipe(
    ofType(...[SearchActions.SEARCH_LOCATION]),
    mergeMap((action: SearchActions.SearchLocation) =>
      this.locationSearchService.getByLocation(action.params).pipe(
        map((results) => {
          return new SearchActions.SearchCarrierSuccess(results);
        }),
        catchError((x) => {
          return of(new SearchActions.SearchCarrierError(x.error));
        })
      )
    )
  );

  @Effect()
  searchLane$ = this.actions$.pipe(
    ofType(...[SearchActions.SEARCH_PREFERRED_LANE]),
    mergeMap((action: SearchActions.SearchPreferredLane) =>
      this.laneSearchService.getSearchPreferredLane(action.params).pipe(
        map((results) => {
          return new SearchActions.SearchCarrierLaneSuccess(results);
        }),
        catchError((x) => {
          return of(new SearchActions.SearchCarrierLaneError(x.error));
        })
      )
    )
  );

  @Effect()
  searchTruckposting$ = this.actions$.pipe(
    ofType(...[SearchActions.SEARCH_TRUCKPOSTING]),
    mergeMap((action: SearchActions.SearchTruckPosting) =>
      this.truckpostingSearchService.getByTruckPosting(action.params).pipe(
        map((results) => {
          return new SearchActions.SearchTruckpostingSuccess(results);
        }),
        catchError((x) => {
          return of(new SearchActions.SearchTruckpostingError(x.error));
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private nameSearchService: NameSearchService,
    private identitySearchService: IdentitySearchService,
    private locationSearchService: LocationSearchService,
    private laneSearchService: CarrierLaneSearchService,
    private truckpostingSearchService: CarrierTruckpostingSearchService
  ) { }
}
