import * as CarrierIdentityActions from '../actions/carrier-identity.actions';
import { CarrierIdentityState } from '../models/carrier-identity-state.model';

var initialState: CarrierIdentityState = {
  loading: false,
  loadingError: null,
  updateLoading: false,
  updateError: null,
  carrierName: null,
  identities: null,
  refCanadian: [],
  refDocket: [],
  refFinancial: [],
  refIdentifier: [],
  refState: [],
  refSystem: [],
};

export function carrierIdentityReducer(state: CarrierIdentityState = initialState, action: CarrierIdentityActions.Actions) {
  switch (action.type) {
    case CarrierIdentityActions.LOAD_CARRIER_IDENTITY: {
      return { ...state, loading: true };
    }

    case CarrierIdentityActions.LOAD_CARRIER_IDENTITY_SUCCESS: {
      return {
        ...state,
        refIdentifier: action.state.refIdentifier,
        refSystem: action.state.refSystem,
        refFinancial: action.state.refFinancial,
        refDocket: action.state.refDocket,
        refState: action.state.refState,
        refCanadian: action.state.refCanadian,
        identities: action.state.identities,
        carrierName: action.state.carrierName,
        loading: false,
      };
    }
    case CarrierIdentityActions.LOAD_CARRIER_IDENTITY_ERROR: {
      return { ...state, loading: false, loadingError: action.errorMessage };
    }

    // Update Identities
    case CarrierIdentityActions.UPDATE_CARRIER_IDENTITY: {
      return { ...state, loading: true, updateLoading: true };
    }
    case CarrierIdentityActions.UPDATE_CARRIER_IDENTITY_SUCCESS: {
      return { ...state, loading: false, updateLoading: false, identities: action.result };
    }
    case CarrierIdentityActions.UPDATE_CARRIER_IDENTITY_ERROR: {
      return { ...state, loading: false, updateLoading: false, updateError: action.errorMessage };
    }

    // Update Carrier Name
    case CarrierIdentityActions.UPDATE_CARRIER_NAME: {
      return { ...state, loading: true, updateLoading: true };
    }
    case CarrierIdentityActions.UPDATE_CARRIER_NAME_SUCCESS: {
      return { ...state, loading: false, updateLoading: false, carrierName: action.result };
    }
    case CarrierIdentityActions.UPDATE_CARRIER_NAME_ERROR: {
      return { ...state, loading: false, updateLoading: false, updateError: action.errorMessage };
    }
    default:
      return state;
  }
}
