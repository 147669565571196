import { SearchState } from '../models/search.model';
import * as SearchActions from '../actions/search.actions';

var initialState: SearchState = {
  loading: false,
  loadingError: null,
  loadingSearch: false,
  loadingSearchError: null,

  truckTypes: [],
  serviceTypes: [],
  certificationTypes: [],

  showNameSearch: true, // default to show name search
  showIDSearch: false,
  showLocationSearch: false,
  showLaneSearch: false,
  showTruckpostingSearch: false,

  showCarrierGrid: true, // default to show carrier grid
  showLaneGrid: false,
  showTruckpostingGrid: false,

  canSearchByID: false,
  canSearchByLane: false,
  canSearchByLocation: false,
  canSearchByName: false,
  canCreateManualCarrier: false,
  canViewPostMatch: false,
  canSearchByTruckposting: false,

  carrierSearchParams: null,
  carrierNameSearchParams: null,
  carrierIdentitySearchParams: null,
  carrierLocationSearchParams: null,
  carrierLaneSearchParams: null,
  carrierTruckpostingSearchParams: null,

  carrierSearchResults: [],
  carrierLaneSearchResults: [],
  carrierTruckpostingSearchResults: [],

  carrierGridSort: [],
  carrierGridPaging: { skip: 0, take: 25 },
  carrierLaneGridSort: [],
  carrierLaneGridPaging: { skip: 0, take: 25 },
  carrierTruckpostingGridSort: [],
  carrierTruckpostingGridPaging: { skip: 0, take: 25 }
};

export function searchReducer(state: SearchState = initialState, action: SearchActions.Actions) {
  switch (action.type) {
    case SearchActions.SELECT_SEARCH_TYPE: {
      switch (action.params.SelectedId) {
        case 1: {
          return {
            ...state,
            showNameSearch: true,
            showIDSearch: false,
            showLocationSearch: false,
            showLaneSearch: false,
            showTruckpostingSearch: false,
            showCarrierGrid: true,
            showLaneGrid: false,
            showTruckpostingGrid: false,
            carrierSearchParams: action.params,
          };
        }
        case 2: {
          return {
            ...state,
            showNameSearch: false,
            showIDSearch: true,
            showLocationSearch: false,
            showLaneSearch: false,
            showTruckpostingSearch: false,
            showCarrierGrid: true,
            showLaneGrid: false,
            showTruckpostingGrid: false,
            carrierSearchParams: action.params,
          };
        }
        case 3: {
          return {
            ...state,
            showNameSearch: false,
            showIDSearch: false,
            showLocationSearch: true,
            showLaneSearch: false,
            showTruckpostingSearch: false,
            showCarrierGrid: true,
            showLaneGrid: false,
            showTruckpostingGrid: false,
            carrierSearchParams: action.params,
          };
        }
        case 4: {
          return {
            ...state,
            showNameSearch: false,
            showIDSearch: false,
            showLocationSearch: false,
            showLaneSearch: true,
            showTruckpostingSearch: false,
            showCarrierGrid: false,
            showLaneGrid: true,
            showTruckpostingGrid: false,
            carrierSearchParams: action.params,
          };
        }
        case 5: {
          return {
            ...state,
            showNameSearch: false,
            showIDSearch: false,
            showLocationSearch: false,
            showLaneSearch: false,
            showTruckpostingSearch: true,
            showCarrierGrid: false,
            showLaneGrid: false,
            showTruckpostingGrid: true,
            carrierSearchParams: action.params,
          };
        }
      }
      return { ...state };
    }
    case SearchActions.SEARCH_LOAD: {
      return { ...state, loading: true };
    }
    case SearchActions.SEARCH_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        truckTypes: action.state.truckTypes,
        serviceTypes: action.state.serviceTypes,
        certificationTypes: action.state.certificationTypes,
        canSearchByID: action.state.canSearchByID,
        canSearchByLane: action.state.canSearchByLane,
        canSearchByLocation: action.state.canSearchByLocation,
        canSearchByName: action.state.canSearchByName,
        canViewPostMatch: action.state.canViewPostMatch,
        canCreateManualCarrier: action.state.canCreateManualCarrier,
        canSearchByTruckposting: action.state.canSearchByTruckposting,
        carrierSearchParams: action.state.carrierSearchParams,
        carrierNameSearchParams: action.state.carrierNameSearchParams,
        carrierIdentitySearchParams: action.state.carrierIdentitySearchParams,
        carrierLocationSearchParams: action.state.carrierLocationSearchParams,
        carrierLaneSearchParams: action.state.carrierLaneSearchParams,
        carrierTruckpostingSearchParams: action.state.carrierTruckpostingSearchParams,
        carrierSearchResults: action.state.carrierSearchResults,
        carrierLaneSearchResults: action.state.carrierLaneSearchResults,
        carrierTruckpostingSearchResults: action.state.carrierTruckpostingSearchResults,
        carrierGridSort: action.state.carrierGridSort,
        carrierGridPaging: action.state.carrierGridPaging,
        carrierLaneGridSort: action.state.carrierLaneGridSort,
        carrierLaneGridPaging: action.state.carrierLaneGridPaging,
        carrierTruckpostingGridSort: action.state.carrierTruckpostingGridSort,
        carrierTruckpostingGridPaging: action.state.carrierTruckpostingGridPaging,
      };
    }
    case SearchActions.SEARCH_NAME: {
      const newState = { ...state, loadingSearch: true, carrierNameSearchParams: action.params };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_ID: {
      const newState = { ...state, loadingSearch: true, carrierIdentitySearchParams: action.params };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_LOCATION: {
      const newState = { ...state, loadingSearch: true, carrierLocationSearchParams: action.params };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_PREFERRED_LANE: {
      const newState = { ...state, loadingSearch: true, carrierLaneSearchParams: action.params };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_TRUCKPOSTING: {
      const newState = { ...state, loadingSearch: true, carrierTruckpostingSearchParams: action.params };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_TRUCKPOSTING_SUCCESS: {
      const newState = { ...state, loadingSearch: false, carrierTruckpostingSearchResults: action.results };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_TRUCKPOSTING_ERROR: {
      return { ...state, loadingSearch: false, loadingSearchError: action.errorMessage };
    }
    case SearchActions.SEARCH_CARRIER_SUCCESS: {
      const newState = { ...state, loadingSearch: false, carrierSearchResults: action.results };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_CARRIER_ERROR: {
      return { ...state, loadingSearch: false, loadingSearchError: action.errorMessage };
    }
    case SearchActions.SEARCH_CARRIER_LANE_SUCCESS: {
      const newState = { ...state, loadingSearch: false, carrierLaneSearchResults: action.results };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.SEARCH_CARRIER_LANE_ERROR: {
      return { ...state, loadingSearchError: action.errorMessage };
    }
    case SearchActions.CARRIER_GRID_SORT: {
      const newState = { ...state, carrierGridSort: action.sort };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.CARRIER_GRID_PAGING: {
      const newState = { ...state, carrierGridPaging: action.paging };
      localStorage.setItem('carrierGridPaging', JSON.stringify(action.paging));
      return newState;
    }
    case SearchActions.CARRIER_LANE_GRID_SORT: {
      const newState = { ...state, carrierLaneGridSort: action.sort };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.CARRIER_LANE_GRID_PAGING: {
      const newState = { ...state, carrierLaneGridPaging: action.paging };
      localStorage.setItem('carrierLaneGridPaging', JSON.stringify(action.paging));
      return newState;
    }
    case SearchActions.CARRIER_TRUCKPOSTING_GRID_SORT: {
      const newState = { ...state, carrierTruckpostingGridSort: action.sort };
      localStorage.setItem('searchState', JSON.stringify(newState));
      return newState;
    }
    case SearchActions.CARRIER_TRUCKPOSTING_GRID_PAGING: {
      const newState = { ...state, carrierTruckloadpostingGridPaging: action.paging };
      localStorage.setItem('carrierTruckloadpostingGridPaging', JSON.stringify(action.paging));
      return newState;
    }
    default:
      return state;
  }
}
