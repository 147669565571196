import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { AppUser } from '../_models/AppUser';
import { UserService } from './user.service';
import { PermissionService } from './permission.service';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { UserAuthenticationSuccess } from '../actions/authentication.actions';

@Injectable()
export class AuthService {
  private manager: UserManager;
  config: Configuration;
  constructor(
    private router: Router,
    private userService: UserService,
    private permsService: PermissionService,
    private store: Store<AppState>
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;

        this.manager = new UserManager(this.getClientSettings());
        this.manager.getUser().then(
          (user) => {
            this.authUser = user;
            if (this.authUser !== null) {
              this.store.dispatch(new UserAuthenticationSuccess(this.authUser, this.config));
            }
          },
          (err) => {
            console.log('manager.getUser failed', err);
          }
        );
      });
  }

  get loggedAppUser(): AppUser {
    const userString = sessionStorage.getItem('userInfo');

    if (userString) {
      return Object.assign({} as AppUser, JSON.parse(userString));
    } else {
      return null;
    }
  }

  set loggedAppUser(value: AppUser) {
    if (value) {
      sessionStorage.setItem('userInfo', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('userInfo');
    }
  }

  get authUser(): User {
    const userString = sessionStorage.getItem('user');

    if (userString) {
      return <User>Object.assign({}, JSON.parse(userString));
    } else {
      return null;
    }
  }

  set authUser(value: User) {
    if (value) {
      sessionStorage.setItem('user', value.toStorageString());
    } else {
      sessionStorage.removeItem('user');
    }
  }

  get userInitials(): string {
    var fn = this.loggedAppUser.firstName;
    var ln = this.loggedAppUser.lastName;
    return fn.substring(0, 1) + ln.substring(0, 1);
  }

  get userName(): string {
    return this.loggedAppUser.appUserName;
  }

  isLoggedIn(): boolean {
    return this.authUser != null && !this.authUser.expired;
  }

  isEnabled(): boolean {
    return this.loggedAppUser && !this.loggedAppUser.isDisabled;
  }

  getAuthorizationHeaderValue(): string {
    return this.authUser ? `${this.authUser.token_type} ${this.authUser.access_token}` : '';
  }

  startAuthentication(returnUrl: string): void {
    returnUrl = returnUrl.indexOf('http') > -1 ? this.router.url : returnUrl;

    this.manager.signinRedirect({ state: { returnUrl: returnUrl } }).then(
      () => { },
      (err) => {
        console.log('signinRedirect failed', err);
      }
    );
  }

  completeAuthentication(): void {
    this.manager.signinRedirectCallback().then(
      (user) => {
        this.authUser = user;
        this.store.dispatch(new UserAuthenticationSuccess(this.authUser, this.config));
        const returnUrl: string = user.state.returnUrl.indexOf('http') > -1 ? this.router.url : user.state.returnUrl;

        this.userService.getCurrent().subscribe(
          (appUser) => {
            this.loggedAppUser = appUser;
          },
          (err) => {
            console.log('No user found, and/or could not be added to the system.', err);
            this.redirectNotAuthorized();
          },
          () => {
            if (this.loggedAppUser.isDisabled) {
              this.redirectNotAuthorized();
            } else {
              this.router.navigate([returnUrl || '/']);
            }
          }
        );
      },
      (err) => {
        console.log('completeAuthentication failed', err);
      }
    );
  }

  /** Log the current user out of the system */
  logout(): void {
    this.userService.getCurrentUserUserLogout().subscribe(() => {
      this.manager
        .signoutRedirect({
          id_token_hint: this.authUser.id_token,
          post_logout_redirect_uri: this.config.authPostLogoutRedirectURI,
        })
        .then(
          () => {
            this.authUser = null;
            this.loggedAppUser = null;
            sessionStorage.clear();
          },
          (err) => {
            console.log('signoutRedirect failed', err);
          }
        );
    });
  }

  redirectNotAuthorized(): void {
    this.router.navigate(['/not-authorized']);
  }

  getClientSettings(): UserManagerSettings {
    return {
      authority: this.config.authUrl,
      client_id: this.config.authClientID,
      redirect_uri: this.config.authRedirectURI,
      post_logout_redirect_uri: this.config.authPostLogoutRedirectURI,
      response_type: this.config.authResponseType,
      scope: this.config.authScope,
      filterProtocolClaims: this.config.authFilterProtocolClaims,
      loadUserInfo: this.config.authLoadUserInfo,
    };
  }

  /** This function will return true if the [appUser] has been assigned the permission matching the PARTICULAR [name] provided.
   * Ex:
   * const a = this.authService.can(PermissionName.canViewPermissions);
   * const b = this.authService.can('canViewPermissions');
   * const c = this.authService.can('ViewPermissions');
   * If no [appUser] is provided, then the function defaults to the current AuthService.loggedAppUser */
  can(name: string, appUser?: AppUser): boolean {
    return this.permsService.can(name, appUser || this.loggedAppUser);
  }

  /** This function will return true if the [appUser] has been assigned ANY of the permissions matching ANY of the [names] provided.
   * If no [appUser] is provided, then the function defaults to the current AuthService.loggedAppUser. */
  some(names: string[], appUser?: AppUser): boolean {
    return this.permsService.some(names, appUser || this.loggedAppUser);
  }

  /** This function will return true if the [appUser] has been assigned ALL of the permissions matching ALL of the [names] provided.
   * If no [appUser] is provided, then the function defaults to the current AuthService.loggedAppUser. */
  all(names: string[], appUser?: AppUser): boolean {
    return this.permsService.all(names, appUser || this.loggedAppUser);
  }
}
