import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { TruckPostState } from '../models/truck-post-state.model';
import { PostMatchDetailsService } from './post-match-details.service';
import { CarrierTruckPostingService } from './carrier-truck-posting.service';
import { CarrierTruckMatchingService } from './carrier-truck-matching.service';
import { RefService } from '../../../shared/references/ref.service';
import { TruckPostingDefaultsService } from '../../carrier-file/carrier-defaults/truckPostingDefaults.service';
import { TruckPost } from '../models/TruckPost';
import { TruckPosting } from '../models/TruckPosting';
import { NoCapacityService } from './carrier-no-capacity.service';

@Injectable({
  providedIn: 'root',
})
export class TruckPostMatchEditService {
  constructor(
    private postMatchService: PostMatchDetailsService,
    private carrierTruckPostingSvc: CarrierTruckPostingService,
    private carrierPostMatchSvc: CarrierTruckMatchingService,
    private refService: RefService,
    private defaultsService: TruckPostingDefaultsService,
    private noCapacityService: NoCapacityService
  ) {}

  getInitialTruckPostState(carrierCode: string): Observable<TruckPostState> {
    return new Observable<TruckPostState>((observer) => {
      var truckPostState = new TruckPostState();
      const carrierFileDetails$ = this.postMatchService.getCarrierFileDetails(carrierCode);
      carrierFileDetails$.subscribe(
        (x) => {
          truckPostState.details = x;
          const combined$ = this.initAPICalls(carrierCode);
          combined$.subscribe(
            (res) => {
              truckPostState.truckPosting = res[0];
              truckPostState.activityTypes = res[1];
              truckPostState.dispositionsTypes = res[2];
              truckPostState.postDefaults = res[3];
              truckPostState.loading = false;
              truckPostState.activityId = 0;
              truckPostState.noCapacity = res[4];
              observer.next(truckPostState);
            },
            (err) => {
              observer.error(err);
            }
          );
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  getTruckPosting(carrierCode: string) {
    return this.carrierTruckPostingSvc.get(carrierCode);
  }

  getPostMatches(carrierCode: string) {
    return this.carrierPostMatchSvc.get(carrierCode);
  }

  initAPICalls(carrierCode: string) {
    const truckPostings$ = this.getTruckPosting(carrierCode);
    const activityTypes$ = this.getActivityTypes();
    const dispositionsTypes$ = this.getDispositionTypes();
    const postDefaults$ = this.getCarrierPostDefaults(carrierCode);
    const noCapacity$ = this.getNoCapacity(carrierCode);

    const combined$ = forkJoin(truckPostings$, activityTypes$, dispositionsTypes$, postDefaults$, noCapacity$);

    return combined$;
  }

  getActivityTypes() {
    return this.refService.getActivityTypes();
  }
  getDispositionTypes() {
    return this.refService.getDispositionTypes();
  }

  getCarrierPostDefaults(carrierCode: string) {
    return this.defaultsService.getCarrierPostDefaults(carrierCode);
  }

  getNoCapacity(carrierCode: string) {
    return this.noCapacityService.getNoCapacity(carrierCode);
  }

  addTruckPosting(carrierCode: string, truckPost: TruckPost) {
    return new Observable<TruckPosting>((observer) => {
      this.carrierTruckPostingSvc.insert(carrierCode, truckPost).subscribe(
        (x) => {
          observer.next(x);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  updateTruckPosting(carrierCode: string, truckPost: TruckPost): Observable<TruckPosting> {
    return new Observable<TruckPosting>((observer) => {
      this.carrierTruckPostingSvc.update(carrierCode, truckPost).subscribe(
        (x) => {
          observer.next(x);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  deleteTruckPosting(carrierCode: string, truckPostID: number) {
    return new Observable<TruckPosting>((observer) => {
      this.carrierTruckPostingSvc.delete(carrierCode, truckPostID).subscribe(
        (x) => {
          observer.next(x);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}
