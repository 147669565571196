import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Template } from './Template';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class TemplatesService {
  config: Configuration;
  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getAll(): Observable<Template[]> {
    return this.httpClient.get<Template[]>(`${this.config.carrierServiceUrl}template`);
  }

  update(template: Template): Observable<Template[]> {
    return this.httpClient.put<Template[]>(`${this.config.carrierServiceUrl}template`, template);
  }

  delete(template: Template): Observable<Template[]> {
    return this.httpClient.delete<Template[]>(`${this.config.carrierServiceUrl}template/${template.templateID}`);
  }
}
