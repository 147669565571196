import { CarrierName } from '../models/CarrierName';

export class CarrierNameDC {
  carrierId: number;
  name: string;
  dbaName: string;
  isManual: boolean;

  public constructor(carrier: CarrierName) {
    this.carrierId = carrier.carrierId;
    this.name = carrier.name;
    this.dbaName = carrier.dbaName;
    this.isManual = carrier.isManual;
  }
}
