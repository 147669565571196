import * as TruckPostActions from '../actions/truck-post.actions';
import { TruckPostState } from '../models/truck-post-state.model';

var initialState: TruckPostState = {
  activityId: 0,
  activityTypes: [],
  carrierCode: null,
  details: null,
  dispositionsTypes: null,
  noCapacity: null,
  postDefaults: null,
  postMatches: null,
  truckPosting: null,
  loading: false,
  loadingError: null,
  savingTruckPost: false,
  truckPostError: null,
  noCapacityError: null,
  savingNoCapacity: false,
  noTruckError: null,
  savingNoTruck: false,
  crmLeadStatus: null,
};

export function truckPostReducer(state: TruckPostState = initialState, action: TruckPostActions.Actions) {
  switch (action.type) {
    case TruckPostActions.LOAD_TRUCK_POST: {
      return { ...state, loading: true };
    }
    case TruckPostActions.LOAD_TRUCK_POST_SUCCESS: {
      return {
        ...state,
        loading: false,
        activityTypes: action.initialState.activityTypes,
        carrierCode: action.initialState.carrierCode,
        details: action.initialState.details,
        dispositionsTypes: action.initialState.dispositionsTypes,
        noCapacity: action.initialState.noCapacity,
        postDefaults: action.initialState.postDefaults,
        postMatches: action.initialState.postMatches,
        truckPosting: action.initialState.truckPosting,
        activityId: 0, // reset activity id to 0 when navigating to post match
      };
    }
    case TruckPostActions.LOAD_TRUCK_POST_ERROR: {
      return { ...state, loading: false, loadingError: action.message };
    }
    case TruckPostActions.CREATE_TRUCK_POST:
    case TruckPostActions.EDIT_TRUCK_POST:
    case TruckPostActions.DELETE_TRUCK_POST: {
      return { ...state, savingTruckPost: true };
    }
    case TruckPostActions.UPDATE_TRUCKPOSTING_SUCCESS: {
      // if last new activity id comes back as zero then use the one from the store.  When updating or deleting we always get back 0.
      // so if a user creates a truck post we should use that activity id even after an update or delete to any other post
      var activityId = action.results.lastNewActivityID == 0 ? state.activityId : action.results.lastNewActivityID;
      return { ...state, truckPosting: action.results, savingTruckPost: false, activityId: activityId };
    }
    case TruckPostActions.UPDATE_TRUCKPOSTING_ERROR: {
      return { ...state, truckPostError: action.errorMessage, savingTruckPost: false };
    }
    case TruckPostActions.CREATE_NO_CAPACITY:
    case TruckPostActions.EDIT_NO_CAPACITY:
    case TruckPostActions.DELETE_NO_CAPACITY: {
      return { ...state, savingNoCapacity: true };
    }
    case TruckPostActions.UPDATE_NO_CAPACITY_SUCCESS: {
      // if last new activity id comes back as zero then use the one from the store.  When updating or deleting we always get back 0.
      // so if a user creates a truck post we should use that activity id even after an update or delete to any other post
      var activityId = action.results.lastNewActivityID == 0 ? state.activityId : action.results.lastNewActivityID;
      return { ...state, noCapacity: action.results, savingNoCapacity: false, activityId: activityId };
    }
    case TruckPostActions.UPDATE_NO_CAPACITY_ERROR: {
      return { ...state, noCapacityError: action.errorMessage, savingNoCapacity: false };
    }
    case TruckPostActions.CREATE_NO_TRUCK:
      return { ...state, savingNoTruck: true };
    case TruckPostActions.UPDATE_NO_TRUCK_SUCCESS: {
      // if last new activity id comes back as zero then use the one from the store.  When updating or deleting we always get back 0.
      // so if a user creates a truck post we should use that activity id even after an update or delete to any other post
      var activityId = action.results.lastNewActivityID == 0 ? state.activityId : action.results.lastNewActivityID;
      return { ...state, truckPosting: action.results, savingNoTruck: false, activityId: activityId };
    }
    case TruckPostActions.UPDATE_NO_TRUCK_ERROR: {
      return { ...state, noTruckError: action.errorMessage, savingNoTruck: false };
    }
    default:
      return state;
  }
}
