import { Component, OnInit } from '@angular/core';
import { TrackingPreference } from '../../models/TrackingPreference';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Submitable } from '../../models/Submitable';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CarrierEditService } from '../../services/carrier-edit-service';

@Component({
  selector: 'truckload-tracking-preference-edit',
  templateUrl: './tracking-preference-edit.component.html',
  styleUrls: ['./tracking-preference-edit.component.scss'],
})
export class TrackingPreferenceEditComponent extends Submitable implements OnInit {
  trackingPreference: TrackingPreference;
  editForm: FormGroup;
  serverError: any;
  allMethods: Array<string> = [];

  constructor(dialog: DialogRef, private carrierEditService: CarrierEditService) {
    super(dialog);

    carrierEditService.carrierChangedSubject.subscribe((x) => {
      this.trackingPreference = x.trackingPreference;

      const method = this.trackingPreference.method;

      this.allMethods = x.refTrackingPreferences.map((r) => r.method);

      if (this.trackingPreference != null) {
        this.editForm = new FormGroup({
          method: new FormControl(method, Validators.required),
        });
      } else {
        this.editForm = new FormGroup({
          method: new FormControl(null, Validators.required),
        });
      }
    });
  }

  ngOnInit() {}
  public save(): void {
    this.disableAndSubmitButton();
    const method = this.editForm.value.method as string;

    let preference = null;
    if (this.trackingPreference) {
      const theMethod = this.trackingPreference;
      preference = {
        method: method,
        trackingPreferenceID: theMethod ? theMethod.trackingPreferenceID : 0,
      };
    } else {
      preference = {
        method: method,
        trackingPreferenceID: 0,
      };
    }

    this.carrierEditService.savePreference(preference).subscribe(
      (data) => {
        this.dialog.close();
      },
      (err) => {
        if (err.status === 403) {
          this.serverError = `You don't have the permission to perform this action!`;
        } else {
          this.serverError = err.error ? err.error : 'Something wrong happened!';
        }
        this.resetButton();
      }
    );
  }

  public cancel(): void {
    this.dialog.close({ text: 'Cancel' });
  }
}
