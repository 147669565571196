import { RefDirection } from '../../shared/references/RefDirection';
import { RefTruckType } from '../../shared/references/RefTruckType';
import { CarrierDefaults } from './CarrierDefaults';
import { CarrierPreferredLane } from './CarrierPreferredLane';
import { RefAPILocation } from '../../shared/Models/RefAPILocation';

export class PreferredLaneState {
  refDirections: RefDirection[];
  refTruckTypes: RefTruckType[];
  carrierDefaults: CarrierDefaults;
  unitOfMeasureTypes: string[];
  stateProvinceList: string[];
  availibilityList: number[];
  preferredLanes: CarrierPreferredLane[];
  canEditLanes: boolean;
  canDeleteLanes: boolean;
  loading: boolean;
  error: string;
  updateLoading: boolean;
  updateError: string;
  defaultOrigin: RefAPILocation;
  defaultDestination: RefAPILocation;
  preferredLanesUpdated: boolean;
}
