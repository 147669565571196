import { Component, OnInit, ViewChild } from '@angular/core';
import { CarrierTruckPostingSearchParameters } from '../../models/CarrierTruckPostingSearchParameters';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import * as SearchActions from '../../actions/search.actions';
import { NgForm, Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'truckload-carrier-truckposting-search',
  templateUrl: './carrier-truckposting-search.component.html',
  styleUrls: ['./carrier-truckposting-search.component.scss']
})
export class CarrierTruckpostingSearchComponent implements OnInit {
  @ViewChild('truckpostingsSearchForm', { static: true }) form: NgForm;
  carrierTruckpostingSearchParams: CarrierTruckPostingSearchParameters;
  truckpostingSearchForm: FormGroup;
  truckTypes: string[];
  public range = { start: null, end: null };
  truckpostingSearchIsDisabled = false;
  daterangeError = '';

  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Search.carrierTruckpostingSearchParams)
      .subscribe((x) => {
        this.carrierTruckpostingSearchParams = x;
        this.carrierTruckpostingSearchParams.StartDate = x.StartDate && Date.parse(x.StartDate.toString()) ? new Date(x.StartDate) : null;
        this.carrierTruckpostingSearchParams.EndDate = x.EndDate && Date.parse(x.EndDate.toString()) ? new Date(x.EndDate) : null;
        this.truckpostingSearchForm = new FormGroup({
          Origin: new FormControl(this.carrierTruckpostingSearchParams.Origin, Validators.required),
          Destination: new FormControl(this.carrierTruckpostingSearchParams.Destination, Validators.required),
          ODH: new FormControl(this.carrierTruckpostingSearchParams.ODH, Validators.required),
          DDH: new FormControl(this.carrierTruckpostingSearchParams.DDH, Validators.required),
          TruckTypeCode: new FormControl(this.carrierTruckpostingSearchParams.TruckTypeCode),
          StartDate: new FormControl(this.carrierTruckpostingSearchParams.StartDate),
          EndDate: new FormControl(this.carrierTruckpostingSearchParams.EndDate),
        });
      });
    this.store
      .select((x) => x.Search.truckTypes)
      .subscribe((x) => {
        this.truckTypes = x;
      });
  }

  disableSearch(): boolean {
    const newParams = Object.assign({}, this.truckpostingSearchForm.value) as CarrierTruckPostingSearchParameters;
    if (newParams.StartDate && newParams.EndDate) {
      const invalid = this.daysdDiff(newParams.EndDate, newParams.StartDate) > 365;
      this.daterangeError = invalid ? 'date range cannot be more than a year' : '';
      return invalid;
    } else if (newParams.StartDate && !newParams.EndDate || !newParams.StartDate && newParams.EndDate) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void { }

  submitTruckpostingSearch() {
    const newParams = Object.assign({}, this.truckpostingSearchForm.value) as CarrierTruckPostingSearchParameters;
    // newParams.StartDate = this.range.start;
    // newParams.EndDate = this.range.end;

    this.store.dispatch(new SearchActions.SearchTruckPosting(newParams));
  }

  daysdDiff(dt2, dt1) {

    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    return Math.ceil(Math.round(diff));

  }
}
