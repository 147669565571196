import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { CarrierErrors } from '../CarrierErrors';
import { CarrierAssociationService } from './association.service';
import { CarrierAssociation } from './CarrierAssociation';
import { AppUser } from './AppUser';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
// import { SetRefreshCarrierCrmLeadStatusOn } from '../../actions/carrier-details.actions';

@Component({
  selector: 'truckload-carrier-association',
  templateUrl: './carrier-association.component.html',
  styleUrls: ['./carrier-association.component.scss'],
})
export class CarrierAssociationComponent implements OnInit {
  @Input()
  carrierCode: string;

  @Input()
  appUser: AppUser;

  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  employeeID: number;
  associationData: CarrierAssociation[];
  newAssociation: CarrierAssociation;

  errorMessages = [];
  itemToRemove: any;

  constructor(private carrierAssociationService: CarrierAssociationService, private auth: AuthService, private store: Store<AppState>) {
    this.removeAssociationConfirmation = this.removeAssociationConfirmation.bind(this);
  }

  ngOnInit() {
    this.getAssociations(this.carrierCode);
  }

  get canAddEditCarrierAssociations(): boolean {
    return this.auth.can(Permissions.EditAssociations);
  }

  get canDeleteCarrierAssociations(): boolean {
    return this.auth.can(Permissions.DeleteAssociations);
  }

  getAssociations(carrierCode: string) {
    if (carrierCode.length) {
      this.carrierAssociationService.getCarrierAssociations(carrierCode).subscribe(
        (data) => {
          this.associationData = data;
        },
        (error) => { }
      );
    }
  }

  addAssociation($event) {
    this.newAssociation = $event;
    this.newAssociation.isRep = false;
    this.carrierAssociationService.insertUpdateCarrierAssociation(this.carrierCode, this.newAssociation).subscribe(
      (data) => {
        this.associationData = data;
      },
      (err) => {
        const error = {} as CarrierErrors;
        error.redirectToCarrierSearch = false;
        error.errors = [`${err.status}: ${err.statusText}`];
        this.openErrorWindow.emit(error);
        this.getAssociations(this.carrierCode);
      },
      () => {
        this.newAssociation = null;
      }
    );
  }

  toggleMainRep(association: CarrierAssociation) {
    /*this.carrierAssociationService.insertUpdateCarrierAssociation(this.carrierCode, association).subscribe(() => {
      this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
    });*/
    this.carrierAssociationService.insertUpdateCarrierAssociation(this.carrierCode, association).subscribe();
    for (let x of this.associationData) {
      if (x.appUserID !== association.appUserID) {
        x.isRep = false;
      }
    }
  }

  public removeAssociationConfirmation(association: CarrierAssociation): boolean {
    this.itemToRemove = association;
    return false;
  }

  removeAssociation(shouldRemove: boolean): void {
    if (!shouldRemove) {
      this.itemToRemove = null;
      return;
    }
    this.carrierAssociationService.deleteCarrierAssociation(this.carrierCode, this.itemToRemove.appUserID).subscribe(
      (data) => {
        this.associationData = data;
        // this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
      },
      (err) => {
        const error = {} as CarrierErrors;
        error.redirectToCarrierSearch = false;
        error.errors = [`${err.status}: ${err.statusText}`];
        this.openErrorWindow.emit(error);
        this.getAssociations(this.carrierCode);
      },
      () => {
        this.itemToRemove = null;
      }
    );
  }
}
