import { ValidatorFn, AbstractControl } from '@angular/forms';

export function notDefaultStringValidator(stringValue: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const match = stringValue !== control.value;
    return match ? null : { defaultStringValidator: true };
  };
}

export function notDefaultObjectValidator(objectValue: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const match = objectValue !== control.value;
    return match ? null : { defaultObjectValidator: true };
  };
}
