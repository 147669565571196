import { RefAPILocation } from '../../../shared/Models/RefAPILocation';

export class TruckPostingDefaults {
  public truckPostingDefaultsID: number;
  public truckLengthValue: number;
  public truckLengthUom: string;
  public contactName: string;
  public truckType: string;
  public truckTypeCode: string;
  public originDeadheadMiles: number;
  public destinationDeadheadMiles: number;
  public origin: RefAPILocation;
  public destination: RefAPILocation;

  constructor() {
    this.originDeadheadMiles = 100;
    this.destinationDeadheadMiles = 250;
    this.truckLengthValue = 53;
    this.truckLengthUom = 'ft';
    this.truckType = 'Dry Van';
    this.truckTypeCode = 'V';
    this.contactName = '';
    this.truckTypeCode = null;
  }
}
