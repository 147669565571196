import { Component, OnInit } from '@angular/core';
import { TrackingPreference } from '../../models/TrackingPreference';
import { CarrierEditService } from '../../services/carrier-edit-service';
import { DialogRef, DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { TrackingPreferenceEditComponent } from '../tracking-preference-edit/tracking-preference-edit.component';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';

@Component({
  selector: 'truckload-tracking-preference',
  templateUrl: './tracking-preference.component.html',
  styleUrls: ['./tracking-preference.component.scss'],
})
export class TrackingPreferenceComponent implements OnInit {
  trackingPreference: TrackingPreference = null;
  loading = false;
  editMode: boolean;

  constructor(private dialogService: DialogService, private carrierEditService: CarrierEditService, public authService: AuthService) {
    carrierEditService.carrierChangedSubject.subscribe((x) => {
      this.trackingPreference = x.trackingPreference;
    });
  }

  ngOnInit() {}

  get canEdit(): boolean {
    return this.authService.can(Permissions.EditTrackingPreference);
  }
  edit() {
    this.toggleEditMode();
    const dialog: DialogRef = this.dialogService.open({
      width: 950,
      content: TrackingPreferenceEditComponent,
    });

    dialog.result.subscribe((result) => {      
      this.toggleEditMode();
    });
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }
}
