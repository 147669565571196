import { forkJoin as observableForkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarrierSummaryDC, CarrierPreferredLane, LaneMatch } from '../../models/LaneMatch';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { Permissions } from '../../../../shared/Enums/Permissions';
import { CarrierLaneMatchingService } from '../../services/carrier-lane-matching.service';
import { Configuration } from '../../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../appstate.model';

@Component({
  selector: 'truckload-carrier-lane-matching',
  templateUrl: './carrier-lane-matching.component.html',
  styleUrls: ['./carrier-lane-matching.component.scss'],
})
export class CarrierLaneMatchingComponent implements OnInit {
  carrierCode: string;
  matchGridData: LaneMatch[] = [];
  errorMessage: string;

  carrierLanes: CarrierPreferredLane[];
  carrierSummary: CarrierSummaryDC;
  config: Configuration;
  constructor(
    private carrierLaneMatchService: CarrierLaneMatchingService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private store: Store<AppState>
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
      this.matchGridData.length = 0;
      observableForkJoin([
        this.carrierLaneMatchService.get(this.carrierCode),
        this.carrierLaneMatchService.getCarrierSummary(this.carrierCode),
      ]).subscribe((response) => {
        this.carrierSummary = response[1];
        this.carrierLanes = response[0];
        this.carrierLanes.forEach((element) => {
          this.matchLane(element);
        });
      });
    });
  }

  get canCreateOffer(): boolean {
    return this.authService.can(Permissions.CreateOffer);
  }

  public matchLane(lane: CarrierPreferredLane): void {
    this.carrierLaneMatchService.match(lane).subscribe((response) => {
      response.forEach((match) => {
        this.matchGridData.push(match);
      });
    });
  }

  public refreshMatches() {
    this.matchGridData.length = 0;
    this.carrierLanes.forEach((element) => {
      this.carrierLaneMatchService.refreshMatch(element).subscribe((response) => {
        response.forEach((match) => {
          this.matchGridData.push(match);
        });
      });
    });
  }

  goToCarrierOffer(dataItem: LaneMatch) {
    window.open(`${this.config.backofficeShipmentEditUrl}/shipment-edit/${dataItem.shipmentID}`);
  }
}
