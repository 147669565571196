import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { InventoryDC } from './InventoryDC';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class InventoryService {
  config: Configuration;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getInventory(carrierCode: string): Observable<InventoryDC> {
    return this.http.get<InventoryDC>(`${this.config.carrierServiceUrl}${carrierCode}/inventory`);
  }

  insertUpdateCarrierInventory(carrierCode: string, carrierInventory: InventoryDC): Observable<InventoryDC> {
    carrierInventory.inventory.forEach((i) => (i.inventory = i.inventory ? i.inventory : 0));
    return this.http.post<InventoryDC>(`${this.config.carrierServiceUrl}${carrierCode}/inventory`, carrierInventory);
  }
}
