import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CarrierAddress } from './CarrierAddress';
import { CarrierErrors } from '../CarrierErrors';
import { Geolocation } from '../../../shared/Models/Geolocation';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { GridComponent } from '@progress/kendo-angular-grid';
import { AddressService } from './address.service';

@Component({
  selector: 'truckload-carrier-addresses',
  templateUrl: './carrier-addresses.component.html',
  styleUrls: ['./carrier-addresses.component.scss'],
})
export class CarrierAddressesComponent implements OnInit {
  public itemToRemove: any;
  @ViewChild('addressGrid', { static: true })
  public addressGrid: GridComponent;
  @Input()
  carrierCode: string;
  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  addresses: CarrierAddress[];
  orignialAddressTypes: string[] = ['Main', 'Billing'];
  availableAddressTypes: string[];
  geolocation: Geolocation = {} as Geolocation;

  canEditAddressType = false;

  manualLocationChecked = false;

  constructor(public addressService: AddressService, public authService: AuthService) {
    this.createNewAddress = this.createNewAddress.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.removeConfirmation = this.removeConfirmation.bind(this);
  }

  ngOnInit() {
    this.addresses = [];
    this.addressService.getAddresses(this.carrierCode).subscribe(
      (data) => {
        this.addresses = data;
      },
      (err) => {
        this.handleError(err);
      }
    );
    this.updateAvailableTypeList();
  }

  get canEditAddresses(): boolean {
    return this.authService.can(Permissions.EditAddress);
  }

  get canEditManualLocation(): boolean {
    return this.authService.can(Permissions.EditManualLocation);
  }

  checkIfRemoveable(type: string): boolean {
    if (type && type.toLocaleLowerCase() !== 'main') {
      return true;
    }
    return false;
  }

  updateAvailableTypeList(): void {
    this.availableAddressTypes = this.orignialAddressTypes.map((x) => x);
    if (this.addresses) {
      for (let i = 0; i < this.addresses.length; i++) {
        const index: number = this.availableAddressTypes.indexOf(this.addresses[i].type);
        if (index !== -1) {
          this.availableAddressTypes.splice(index, 1);
        }
      }
    }
  }

  createNewAddress(args: any): any {
    this.updateAvailableTypeList();
    return {} as CarrierAddress;
  }
  canAddAddress(): boolean {
    if (!this.canEditAddresses) {
      return false;
    }
    if (this.addresses.length === this.orignialAddressTypes.length) {
      return false;
    }
    return true;
  }

  saveAddress({ sender, rowIndex, dataItem, isNew }): void {
    if (!this.manualLocationChecked) {
      dataItem.city = this.geolocation.city;
      dataItem.stateProvince = this.geolocation.stateProvince;
      dataItem.postalCode = this.geolocation.postalCode;
      dataItem.countryCode = this.geolocation.countryCode;
    }

    this.addressService.insertUpdateCarrierAddress(dataItem, this.carrierCode).subscribe(
      (data) => {
        this.addresses = data;
      },
      (err) => {
        this.handleError(err, true, sender, isNew, rowIndex);
      }
    );
  }

  editAddress(t: any): void {
    this.canEditAddressType = this.checkIfRemoveable(t.dataItem.type);
    if (this.canEditAddressType === true) {
      this.updateAvailableTypeList();
      this.availableAddressTypes.push(t.dataItem.type);
    }
  }

  removeAddress({ sender, rowindex, dataItem }): void {
    this.addressService.deleteCarrierAddress(this.carrierCode, dataItem.carrierAddressID).subscribe((data) => {
      this.addresses = data;
    });
  }
  public cancelAddress({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public confirmRemove(shouldRemove: boolean): void {
    if (!shouldRemove) {
      this.itemToRemove = null;
      return;
    }
    this.addressService.deleteCarrierAddress(this.carrierCode, this.itemToRemove.carrierAddressID).subscribe(
      (data) => {
        this.addresses = data;
      },
      (err) => {
        this.handleError(err);
      },
      () => {
        this.itemToRemove = null;
      }
    );
  }

  public removeConfirmation(dataItem): any {
    this.itemToRemove = dataItem;
    return false;
  }

  handleError(err: any, editing?, sender?, isNew?, rowIndex?) {
    const errors = {} as CarrierErrors;

    if (err.status === 404) {
      errors.errors = [];
      errors.errors.push(err.error);
      errors.redirectToCarrierSearch = true;
      this.openErrorWindow.emit(errors);
      return;
    }

    if (err.status === 400) {
      errors.redirectToCarrierSearch = false;
      errors.errors = [];
      for (const fieldName in err.error) {
        if (err.error.hasOwnProperty(fieldName)) {
          errors.errors.push(`${err.error[fieldName]}`);
        }
      }
      if (editing) {
        this.openErrorWindow.emit(errors);
        sender.editRow(isNew ? 0 : rowIndex);
      }
    }
  }
}
