import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppInsights } from 'applicationinsights-js';
import { Configuration } from './shared/configuration/Configuration';
import { AppState } from './appstate.model';
@Injectable()
export class AppInsightService {
  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        if (!x.appInsights_InstrumentationKey) return;
        if (!AppInsights.config) {
          var aiConfig: Microsoft.ApplicationInsights.IConfig = {
            instrumentationKey: x.appInsights_InstrumentationKey,
          };
          AppInsights.downloadAndSetup(aiConfig);
          AppInsights.trackPageView();
        }
      });
  }
}
