import { CarrierFileDetails } from './CarrierFileDetails';
import { CarrierStatus } from './CarrierStatus';
import { CrmLeadStatus } from './CrmLeadStatus';

export class CarrierDetailsState {
  loading: boolean;
  loadingError: string;
  updateLoading: false;
  updateError: string;
  details: CarrierFileDetails;
  status: CarrierStatus;
  crmLeadStatus: CrmLeadStatus;
  shouldRefreshCrmLeadStatus: boolean;
}
