import { RefAPILocation } from '../../shared/Models/RefAPILocation';

export class CarrierLocationSearchParameters {
  Address: RefAPILocation;
  Radius: number;
  TruckTypes: string[];
  ServiceTypes: string[];
  CertificationTypes: string[];
  InsuranceValue: number;

  constructor() {
    this.Address = null;
    this.Radius = 0;
    this.InsuranceValue = 0;
    this.TruckTypes = [];
    this.ServiceTypes = [];
    this.CertificationTypes = [];
  }
}
