import { Component, Input, ViewEncapsulation, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { TruckPost, TruckPostAddress } from '../../models/TruckPost';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { Submitable } from '../../../models/Submitable';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AppState } from '../../../../appstate.model';
import { Store } from '@ngrx/store';
import { TruckPostingDefaults } from '../../../../carrier/carrier-file/carrier-defaults/TruckPostingDefaults';
import { CreateTruckPost, EditTruckPost } from '../../actions/truck-post.actions';
import { RefAPILocation } from '../../../../shared/Models/RefAPILocation';
//import { SetRefreshCarrierCrmLeadStatusOn } from '../../../actions/carrier-details.actions';

@Component({
  selector: 'truckload-carrier-truck-posting-edit',
  templateUrl: './carrier-truck-posting-edit.component.html',
  styleUrls: ['./carrier-truck-posting-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierTruckPostingEditComponent extends Submitable implements AfterViewInit, OnInit {
  @Input()
  public truckPost: TruckPost;
  @Input()
  public isNew = false;
  @Input()
  public carrierCode: string;
  @ViewChild('origin', { static: true }) public origin: any;
  @ViewChild('destination', { static: true }) public destination: any;

  timeSteps = { minute: 15 };
  active = false;
  serverError: string;
  truckPostForm: FormGroup;
  truckPostDefaults: TruckPostingDefaults;
  lastActivityId: number;

  constructor(dialog: DialogRef, private store: Store<AppState>) {
    super(dialog);
    this.store
      .select((x) => x.TruckPost.activityId)
      .subscribe((x) => {
        this.lastActivityId = x;
      });

    this.store
      .select((x) => x.TruckPost.savingTruckPost)
      .subscribe((x) => {
        if (x) {
          this.disableAndSubmitButton('Saving...');
        } else {
          if (this.isNew) {
            this.resetButton('Save');
            let defaultPost = this.createDefaultTruckPost();
            this.resetForm(defaultPost);
          } else if (this.truckPostForm) {
            this.dialog.close();
          }
        }
      });
  }

  createDefaultTruckPost(): TruckPost {
    const date = new Date();
    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return {
      truckPostingID: 0,
      dispositionType: 'Trucks Added',
      activityType: 'General Call',
      origin: this.refApiLocationToTruckPostAddress(this.truckPostDefaults.origin, 'origin'),
      destination: this.refApiLocationToTruckPostAddress(this.truckPostDefaults.destination, 'destination'),
      originDeadheadMiles: this.truckPostDefaults.originDeadheadMiles,
      destinationDeadheadMiles: this.truckPostDefaults.destinationDeadheadMiles,
      contactName: this.truckPostDefaults.contactName,
      truckLengthUom: 'ft',
      truckLengthValue: this.truckPostDefaults.truckLengthValue,
      truckType: this.truckPostDefaults.truckType,
      truckTypeCode: this.truckPostDefaults.truckTypeCode,
      isTeam: false,
      emptyDateTime: date,
    } as TruckPost;
  }

  resetForm(model: TruckPost) {
    this.truckPostForm.reset({
      truckPostingID: 0,
      dispositionType: 'Trucks Added',
      activityType: 'General Call',
      origin: model.origin,
      destination: model.destination,
      originDeadheadMiles: model.originDeadheadMiles,
      destinationDeadheadMiles: model.destinationDeadheadMiles,
      contactName: model.contactName,
      truckLengthUom: 'ft',
      truckLengthValue: model.truckLengthValue,
      truckType: model.truckType,
      truckTypeCode: model.truckTypeCode,
      isTeam: model.isTeam,
      emptyDate: model.emptyDateTime,
      emptyTime: model.emptyDateTime,
    });

    this.origin.matches.push(this.truckPostDefaults.origin);
    this.origin.address = this.truckPostDefaults.origin;
    this.destination.matches.push(this.truckPostDefaults.destination);
    this.destination.address = this.truckPostDefaults.destination;

    setTimeout(() => {
      this.origin.autocomplete.searchbar.input.nativeElement.focus();
    }, 0);
  }

  createForm(model: TruckPost) {
    this.truckPostForm = new FormGroup({
      truckPostingID: new FormControl(model.truckPostingID),
      truckLengthUom: new FormControl('ft'),
      truckLengthValue: new FormControl(model.truckLengthValue, Validators.required),
      emptyDate: new FormControl(model.emptyDateTime, [Validators.required, this.validateDate]),
      emptyTime: new FormControl(model.emptyDateTime, Validators.required),
      contactName: new FormControl(model.contactName, Validators.required),
      note: new FormControl(model.note),
      isTeam: new FormControl(model.isTeam, Validators.required),
      truckTypeCode: new FormControl(model.truckTypeCode, Validators.required),
      originDeadheadMiles: new FormControl(model.originDeadheadMiles, Validators.required),
      destinationDeadheadMiles: new FormControl(model.destinationDeadheadMiles, Validators.required),
      origin: new FormControl(model.origin, Validators.required),
      destination: new FormControl(model.destination, Validators.required),
      activityType: new FormControl(model.activityType, Validators.required),
      dispositionType: new FormControl(model.dispositionType, Validators.required),
    });
  }

  ngOnInit(): void {
    this.store
      .select((x) => x.TruckPost.postDefaults)
      .subscribe((x) => {
        this.truckPostDefaults = x;
        if (this.isNew) {
          let defaultPost = this.createDefaultTruckPost();
          this.createForm(defaultPost);
        } else {
          this.createForm(this.truckPost);
        }
      });
    setTimeout(() => {
      this.origin.autocomplete.searchbar.input.nativeElement.focus();
    }, 0);
  }

  ngAfterViewInit() {}

  public onSave(): void {
    var newTruckPost = Object.assign({}, this.truckPostForm.value) as TruckPost;
    newTruckPost.activityID = this.lastActivityId;
    var emptyDate = this.truckPostForm.get('emptyDate').value as Date;
    var emptyTime = this.truckPostForm.get('emptyTime').value as Date;
    newTruckPost.emptyDateTime = emptyDate;
    newTruckPost.emptyDateTime.setHours(emptyTime.getHours());
    newTruckPost.emptyDateTime.setMinutes(emptyTime.getMinutes());
    newTruckPost.emptyDateTime.setSeconds(0);
    newTruckPost.emptyDateTime.setMilliseconds(0);

    if (this.isNew) {
      this.store.dispatch(new CreateTruckPost(this.carrierCode, newTruckPost));
    } else {
      this.store.dispatch(new EditTruckPost(this.carrierCode, newTruckPost));
    }
    //this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
  }

  public onCancel(): void {
    this.dialog.close();
  }

  private validateDate(control: AbstractControl): ValidationErrors[] {
    const errors = [];
    const editDate = new Date(control.value);
    editDate.setHours(0, 0, 0);
    editDate.setSeconds(0, 0);
    const curDate = new Date();
    curDate.setHours(0, 0, 0);
    curDate.setSeconds(0, 0);
    if (editDate < curDate) {
      errors.push('Date entered earlier than today');
    }
    return errors;
  }

  private refApiLocationToTruckPostAddress(apiLocation: RefAPILocation, type: string): TruckPostAddress {
    if (!apiLocation) {
      return null;
    }
    return {
      refAPILocationID: apiLocation.refAPILocationID,
      city: apiLocation.city,
      cityState: apiLocation.cityState,
      stateProvince: apiLocation.stateProvince,
      countryCode: apiLocation.countryCode,
      latitude: apiLocation.latitude,
      longitude: apiLocation.longitude,
      type: type,
    } as TruckPostAddress;
  }
}
