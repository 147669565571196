import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule, DropDownListModule, MultiSelectModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CarrierModule } from '../carrier/carrier.module';
import { SharedModule } from '../shared/shared.module';
import { SearchRoutingModule } from './search-routing.module';

// services
import { SearchService } from './services/search.service';
import { CarrierLaneSearchService } from './services/carrier-lane-search.service';
import { IdentitySearchService } from './services/identity.service';
import { LocationSearchService } from './services/location-search.service';
import { NameSearchService } from './services/name-search.service';

// components
import { CarrierSearchComponent } from './components/carrier-search-page/carrier-search.component';
import { CarrierLaneSearchComponent } from './components/carrier-lane-search/carrier-lane-search.component';
import { IdentityComponent } from './components/identity/identity.component';
import { LocationSearchComponent } from './components/location/location-search.component';
import { NameSearchComponent } from './components/name/name-search.component';
import { LaneGridComponent } from './components/lane-grid/lane-grid.component';
import { CarrierGridComponent } from './components/carrier-grid/carrier-grid.component';
import { StoreModule } from '@ngrx/store';
import { searchReducer } from './reducers/search.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './effects/search.effect';
import { appReducer } from '../app.reducer';
import { AppEffects } from '../app.effects';
import { CarrierTruckpostingSearchComponent } from './components/carrier-truckposting-search/carrier-truckposting-search.component';
import { CarrierTruckpostingGridComponent } from './components/carrier-truckposting-grid/carrier-truckposting-grid.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    GridModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    DropDownListModule,
    MultiSelectModule,
    ComboBoxModule,
    InputsModule,
    DatePickerModule,
    SharedModule,
    SearchRoutingModule,
    CarrierModule,
    StoreModule.forFeature('Search', searchReducer),
    EffectsModule.forFeature([SearchEffects]),
  ],
  declarations: [
    CarrierSearchComponent,
    CarrierLaneSearchComponent,
    IdentityComponent,
    LocationSearchComponent,
    NameSearchComponent,
    LaneGridComponent,
    CarrierGridComponent,
    CarrierTruckpostingSearchComponent,
    CarrierTruckpostingGridComponent
  ],
  providers: [SearchService, IdentitySearchService, LocationSearchService, NameSearchService, CarrierLaneSearchService],
})
export class SearchModule { }
