import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

// module types
import { CarrierPreferredLane } from '../../models/CarrierPreferredLane';
import { AppState } from '../../../appstate.model';
import { DeletePreferredLane, LoadPreferredLanes } from '../../actions/preferred-lanes.actions';
import { ActivatedRoute } from '@angular/router';
import { DialogRef, DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { PreferredLaneEditComponent } from '../preferred-lane-edit/preferred-lane-edit.component';
// import { SetRefreshCarrierCrmLeadStatusOn } from '../../actions/carrier-details.actions';

@Component({
  selector: 'truckload-carrier-preferred-lanes',
  templateUrl: './preferred-lanes.component.html',
  styleUrls: ['./preferred-lanes.component.scss'],
})
export class PreferredLanesComponent implements OnInit {
  carrierCode: string;
  loading: boolean;
  error: string;

  deleteLoading: boolean;

  canEditLanes: boolean;
  canDeleteLanes: boolean;

  laneGridData: GridDataResult;

  itemToRemove: any;
  state: State = {
    skip: 0,
    take: 5,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  carrierPreferredLanes: CarrierPreferredLane[] = [];

  constructor(private activatedRoute: ActivatedRoute, private dialogService: DialogService, private store: Store<AppState>) {
    store
      .select((x) => x.PreferredLanes.preferredLanes)
      .subscribe((x) => {
        this.carrierPreferredLanes = x;
        this.laneGridData = process(this.carrierPreferredLanes, this.state);
      });

    store
      .select((x) => x.PreferredLanes)
      .subscribe((x) => {
        this.canEditLanes = x.canEditLanes;
        this.canDeleteLanes = x.canDeleteLanes;
      });

    store
      .select((x) => x.PreferredLanes.loading)
      .subscribe((x) => {
        this.loading = x;
      });

    store
      .select((x) => x.PreferredLanes.error)
      .subscribe((x) => {
        this.error = x;
      });

    store
      .select((x) => x.PreferredLanes.updateLoading)
      .subscribe((x) => {
        this.loading = x;

        if (!x) {
          this.itemToRemove = null;
        }
      });

    /*store
      .select((x) => x.PreferredLanes.preferredLanesUpdated)
      .subscribe((x) => {
        if (x) {
          this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
        }
      });*/
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
    });
  }
  edit(dataItem: CarrierPreferredLane) {
    const dialog: DialogRef = this.dialogService.open({
      width: 950,
      content: PreferredLaneEditComponent,
    });
    dialog.content.instance.preferredLane = dataItem;
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
      }
    });
  }

  add() {
    const dialog: DialogRef = this.dialogService.open({
      width: 950,
      content: PreferredLaneEditComponent,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.laneGridData = process(this.carrierPreferredLanes, this.state);
  }

  public confirmRemove(shouldRemove: boolean): void {
    if (!shouldRemove) {
      this.itemToRemove = null;
      return;
    }

    const component = this;

    if (shouldRemove) {
      this.store.dispatch(new DeletePreferredLane(this.carrierCode, this.itemToRemove.carrierPreferredLaneID));
    }
  }
  public removeConfirmation(dataItem) {
    this.itemToRemove = dataItem;
  }
}
