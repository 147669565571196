import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AppUserService } from './appuser.service';
import { AppUser } from './AppUser';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'truckload-appuser-autocomplete',
  templateUrl: './appuser-autocomplete.component.html',
  styleUrls: ['./appuser-autocomplete.component.scss'],
})
export class AppUserAutoCompleteComponent implements OnInit {
  @Input()
  selectedAppUser: AppUser = {} as AppUser;
  @Input()
  index: number;
  @Input()
  disabled: boolean;
  @Output()
  selectedAppUserChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('autocomplete', { static: true })
  public autocomplete: ComboBoxComponent;
  public matches: Array<AppUser> = [];
  constructor(public appUserService: AppUserService) {}

  ngOnInit() {
    if (this.selectedAppUser) {
      this.matches.push(this.selectedAppUser);
    }
  }
  public handleFilter(filter: string) {
    if (filter.length < 3) {
      this.autocomplete.toggle(false);
      return;
    }
    if (this.appUserService.salesReps) {
      this.matches = this.appUserService.salesReps.filter((s) => s.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
    } else {
      this.appUserService.getSalesReps().subscribe((x) => {
        this.matches = this.appUserService.salesReps.filter((s) => s.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
      });
    }
  }

  public appUserChange(value: AppUser): void {
    this.selectedAppUser = value;
    this.selectedAppUserChange.emit({
      index: this.index,
      appUser: Object.assign({} as AppUser, this.selectedAppUser),
    });
  }
}
