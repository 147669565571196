import { PreferredLaneState } from '../models/preferred-lane-state.model';
import { UnitOfMeasureType } from '../../shared/Enums/UnitOfMeasureType';
import { StateList } from '../../shared/Enums/StateList';
import * as PreferredLanesActions from '../actions/preferred-lanes.actions';

var initialState: PreferredLaneState = {
  loading: false,
  error: null,
  updateLoading: false,
  updateError: null,
  unitOfMeasureTypes: Object.keys(UnitOfMeasureType),
  stateProvinceList: Object.keys(StateList),
  availibilityList: [1, 2, 3, 4, 5, 6, 7],
  canDeleteLanes: false,
  canEditLanes: false,
  carrierDefaults: null,
  preferredLanes: [],
  refDirections: [],
  refTruckTypes: [],
  defaultDestination: null,
  defaultOrigin: null,
  preferredLanesUpdated: false,
};

export function preferredLanesReducer(state: PreferredLaneState = initialState, action: PreferredLanesActions.Actions) {
  switch (action.type) {
    case PreferredLanesActions.LOAD_PREFERREDLANES: {
      return { ...state, loading: true };
    }
    case PreferredLanesActions.LOAD_PREFERREDLANES_SUCCESS: {
      return {
        ...state,
        canEditLanes: action.state.canEditLanes,
        canDeleteLanes: action.state.canDeleteLanes,
        refTruckTypes: action.state.refTruckTypes,
        refDirections: action.state.refDirections,
        carrierDefaults: action.state.carrierDefaults,
        preferredLanes: action.state.preferredLanes,
        loading: false,
        preferredLanesUpdated: false,
      };
    }
    case PreferredLanesActions.LOAD_PREFERREDLANES_ERROR: {
      return { ...state, loading: false, error: action.errorMessage, preferredLanesUpdated: false };
    }
    case PreferredLanesActions.CREATE_PREFERRED_LANE: {
      return { ...state, updateLoading: true, preferredLanesUpdated: false };
    }
    case PreferredLanesActions.EDIT_PREFERRED_LANE: {
      return { ...state, updateLoading: true, preferredLanesUpdated: false };
    }
    case PreferredLanesActions.DELETE_PREFERRED_LANE: {
      return { ...state, updateLoading: true, preferredLanesUpdated: false };
    }
    case PreferredLanesActions.UPDATE_PREFERRED_LANE_SUCCESS: {
      return { ...state, updateLoading: false, preferredLanes: action.lanes, preferredLanesUpdated: true };
    }
    case PreferredLanesActions.UPDATE_PREFERRED_LANE_ERROR: {
      return { ...state, updateLoading: false, updateError: action.errorMessage, preferredLanesUpdated: false };
    }
    default:
      return state;
  }
}
