import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './profile/profile.component';
import { LoggedInGuard } from './shared/guards/auth/logged-in.guard';
import { UserEnabledGuard } from './shared/guards/auth/user-enabled.guard';

import { NotFoundComponent } from './not-found.component';
import { NotAuthorizedComponent } from './authentication/not-authorized/not-authorized.component';

import { CarrierManagerGuard } from './shared/guards/carrier/carrier-manager.guard';
import { AdminManagerGuard } from './shared/guards/admin/admin-manager.guard';
import { LaneLoadHistoryGuard } from './shared/guards/misc/lane-load-history.guard';
import { HealthComponent } from './health/health.component';
import { VerifyComponent } from './verify/verify.component';

import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';

const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'health',
    component: HealthComponent,
  },
  {
    path: 'verify',
    component: VerifyComponent,
    data: {
      isPublic: true,
    }
  },
  {
    path: '',
    canActivate: [LoggedInGuard, UserEnabledGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          breadcrumb: 'Profile',
        },
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule),
        canActivate: [CarrierManagerGuard],
        data: {
          breadcrumb: 'Portfolio',
        },
      },
      {
        path: 'mymatches',
        loadChildren: () => import('./my-matches/my-matches.module').then(m => m.MyMatchesModule),
        canActivate: [CarrierManagerGuard],
        data: {
          breadcrumb: 'My Matches',
        },
      },
      {
        path: 'search',
        loadChildren: () => import('./carrier-search/search.module').then(m => m.SearchModule),
        canActivate: [CarrierManagerGuard],
        data: {
          breadcrumb: 'Search',
        },
      },
      {
        path: 'lanehistory',
        loadChildren: () => import('./lane-history/lane-history.module').then(m => m.LaneHistoryModule),
        canActivate: [LaneLoadHistoryGuard],
        data: {
          breadcrumb: 'Lane History',
        },
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin-v2/admin.module').then(m => m.NewAdminModule),
        canActivate: [AdminManagerGuard],
        data: {
          breadcrumb: 'Admin',
        },
      },
      { path: '', redirectTo: 'portfolio', pathMatch: 'full' },
      {
        path: 'search/:useLastSearch',
        redirectTo: 'search',
        pathMatch: 'full',
      },
      { path: 'home', redirectTo: 'portfolio', pathMatch: 'full' },
      { path: '**', component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
