import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as TruckPostActions from '../actions/truck-post.actions';
import { TruckPostMatchEditService } from '../services/truck-post-match-edit.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NoCapacityService } from '../services/carrier-no-capacity.service';
import { CarrierTruckPostingService } from '../services/carrier-truck-posting.service';

@Injectable()
export class TruckPostEffects {
  @Effect()
  loadTruckPost$ = this.actions$.pipe(
    ofType(TruckPostActions.LOAD_TRUCK_POST),
    mergeMap((x: TruckPostActions.LoadTruckPost) =>
      this.truckPostEditService.getInitialTruckPostState(x.carrierCode).pipe(
        map((initialState) => {
          return new TruckPostActions.LoadTruckPostSuccess(initialState);
        }),
        catchError((err) => {
          return of(new TruckPostActions.LoadTruckPostError(err.error));
        })
      )
    )
  );

  @Effect()
  createTruckPost$ = this.actions$.pipe(
    ofType(TruckPostActions.CREATE_TRUCK_POST),
    mergeMap((x: TruckPostActions.CreateTruckPost) =>
      this.truckPostEditService.addTruckPosting(x.carrierCode, x.truckPost).pipe(
        map((updatedTruckPosting) => {
          this.showNotification('Truckpost created successfully', 'info');
          return new TruckPostActions.UpdateTruckPostSuccess(updatedTruckPosting);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateTruckPostError(err.error));
        })
      )
    )
  );

  @Effect()
  editTruckPost$ = this.actions$.pipe(
    ofType(TruckPostActions.EDIT_TRUCK_POST),
    mergeMap((x: TruckPostActions.EditTruckPost) =>
      this.truckPostEditService.updateTruckPosting(x.carrierCode, x.truckPost).pipe(
        map((updatedTruckPosting) => {
          this.showNotification('Truckpost updated successfully', 'info');
          return new TruckPostActions.UpdateTruckPostSuccess(updatedTruckPosting);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateTruckPostError(err.error));
        })
      )
    )
  );

  @Effect()
  deleteTruckPost$ = this.actions$.pipe(
    ofType(TruckPostActions.DELETE_TRUCK_POST),
    mergeMap((x: TruckPostActions.DeleteTruckPost) =>
      this.truckPostEditService.deleteTruckPosting(x.carrierCode, x.truckPost.truckPostingID).pipe(
        map((updatedTruckPosting) => {
          this.showNotification('Truckpost deleted successfully', 'info');
          return new TruckPostActions.UpdateTruckPostSuccess(updatedTruckPosting);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateTruckPostError(err.error));
        })
      )
    )
  );

  @Effect()
  createNoCapacity$ = this.actions$.pipe(
    ofType(TruckPostActions.CREATE_NO_CAPACITY),
    mergeMap((x: TruckPostActions.CreateNoCapacity) =>
      this.noCapacityService.insertNoCapacity(x.carrierCode, x.noCapacity).pipe(
        map((updatedNoCapacity) => {
          this.showNotification('No capacity created successfully', 'info');
          return new TruckPostActions.UpdateNoCapacitySuccess(updatedNoCapacity);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateNoCapacityError(err.error));
        })
      )
    )
  );

  @Effect()
  editNoCapacity$ = this.actions$.pipe(
    ofType(TruckPostActions.EDIT_NO_CAPACITY),
    mergeMap((x: TruckPostActions.EditNoCapacity) =>
      this.noCapacityService.updateNoCapacity(x.carrierCode, x.noCapacity).pipe(
        map((updatedNoCapacity) => {
          this.showNotification('No capacity updated successfully', 'info');
          return new TruckPostActions.UpdateNoCapacitySuccess(updatedNoCapacity);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateNoCapacityError(err.error));
        })
      )
    )
  );

  @Effect()
  deleteNoCapacity$ = this.actions$.pipe(
    ofType(TruckPostActions.DELETE_NO_CAPACITY),
    mergeMap((x: TruckPostActions.DeleteNoCapacity) =>
      this.noCapacityService.deleteNoCapacity(x.carrierCode, x.noCapacity.carrierNoCapacityID).pipe(
        map((updatedNoCapacity) => {
          this.showNotification('No capacity deleted successfully', 'info');
          return new TruckPostActions.UpdateNoCapacitySuccess(updatedNoCapacity);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateNoCapacityError(err.error));
        })
      )
    )
  );

  @Effect()
  createNoTruck$ = this.actions$.pipe(
    ofType(TruckPostActions.CREATE_NO_TRUCK),
    mergeMap((x: TruckPostActions.CreateNoTruck) =>
      this.truckPostService.noTruck(x.carrierCode).pipe(
        map((updatedTruckPosting) => {
          this.showNotification('No truck create successfully', 'info');
          return new TruckPostActions.UpdateNoTruckSuccess(updatedTruckPosting);
        }),
        catchError((err) => {
          this.showNotification(err.error, 'error');
          return of(new TruckPostActions.UpdateNoTruckError(err.error));
        })
      )
    )
  );

  public showNotification(msg: string, style: any): void {
    this.notificationService.show({
      content: msg,
      cssClass: 'button-notification ',
      animation: { type: 'slide', duration: 500 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: style, icon: true },
    });
  }

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private truckPostEditService: TruckPostMatchEditService,
    private noCapacityService: NoCapacityService,
    private truckPostService: CarrierTruckPostingService
  ) {}
}
