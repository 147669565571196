import { RefTruckType } from '../../shared/references/RefTruckType';
import { RefAPILocation } from '../../shared/Models/RefAPILocation';

export class CarrierDefaults {
  carrierDefaultID: number;
  carrierID: number;
  originDeadheadMiles: number;
  destinationDeadheadMiles: number;
  truckLengthValue: number;
  truckType: RefTruckType;
  truckTypeCode: string;
  origin: RefAPILocation;
  destination: RefAPILocation;
  constructor() {
    this.originDeadheadMiles = 0;
    this.destinationDeadheadMiles = 0;
    this.truckLengthValue = 0;
  }
}
