import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierServiceModel } from './CarrierServiceModel';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class ServiceService {
  config: Configuration;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<CarrierServiceModel[]> {
    return this.http.get<CarrierServiceModel[]>(`${this.config.carrierServiceUrl}${carrierCode}/service`);
  }

  save(carrierCode: string, serviceIDs: number[]): Observable<number> {
    return this.http.post<number>(`${this.config.carrierServiceUrl}${carrierCode}/service`, serviceIDs);
  }
}
