import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../Services/page.service';
import { AppUser } from '../../../authentication/_models/AppUser';
import { PortfolioService } from '../../services/portfolio.service';
@Component({
  selector: 'truckload-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.scss'],
})
export class PortfolioPageComponent implements OnInit {
  portfolioRep: AppUser;
  errors: any[] = [];
  showLoading: boolean;

  constructor(private pageService: PageService, private portfolioService: PortfolioService) {}

  ngOnInit() {
    this.pageService.setPageTitle('Portfolio');
    var appUser = localStorage.getItem('portfolioRep');
    this.portfolioRep = appUser ? JSON.parse(appUser) : null;
  }

  changeAppUser($event) {
    localStorage.setItem('portfolioRep', JSON.stringify($event.appUser));
    this.portfolioRep = $event.appUser;
    this.portfolioService.portfolioRepChanged(this.portfolioRep.appUserName);
  }
}
