import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as CarrierDetailsActions from '../actions/carrier-details.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DetailsService } from '../services/details.service';
import { CarrierStatus } from '../models/CarrierStatus';

@Injectable()
export class CarrierDetailsEffects {
  @Effect()
  loadCarrierDetails = this.actions$.pipe(
    ofType(...[CarrierDetailsActions.LOAD_CARRIER_DETAILS]),
    mergeMap((action: CarrierDetailsActions.LoadCarrierDetails) =>
      this.detailsService.getState(action.carrierCode).pipe(
        map((result) => {
          return new CarrierDetailsActions.LoadCarrierDetailsSuccess(result);
        }),
        catchError((x) => {
          return of(new CarrierDetailsActions.LoadCarrierDetailsError(x.error));
        })
      )
    )
  );

  @Effect()
  updateCarrierDetails = this.actions$.pipe(
    ofType(...[CarrierDetailsActions.UPDATE_CARRIER_DETAILS]),
    mergeMap((action: CarrierDetailsActions.UpdateCarrierDetails) =>
      this.detailsService.updateCarrierDetails(action.details.carrierCode, action.details).pipe(
        map((result) => {
          return new CarrierDetailsActions.UpdateCarrierDetailsSuccess(result);
        }),
        catchError((x) => {
          return of(new CarrierDetailsActions.UpdateCarrierDetailsError(x.error));
        })
      )
    )
  );

  @Effect()
  updateCarrierStatus = this.actions$.pipe(
    ofType(...[CarrierDetailsActions.UPDATE_CARRIER_STATUS]),
    mergeMap((action: CarrierDetailsActions.UpdateCarrierStatus) =>
      this.detailsService.updateCarrierStatus(action.carrierCode, action.status).pipe(
        map((result) => {
          const newStatus = {
            status: result.status,
            isManuallyMonitored: result.isManual,
            isTruckerToolsQualified: result.isTruckerToolsQualified,
            isAuthorizedToQuickBook: result.isAuthorizedToQuickBook,
            carrierTier: result.carrierTier,
          } as CarrierStatus;
          return new CarrierDetailsActions.UpdateCarrierStatusSuccess(newStatus, result.crmLeadStatus);
        }),
        catchError((x) => {
          return of(new CarrierDetailsActions.UpdateCarrierDetailsError(x.error));
        })
      )
    )
  );

  @Effect()
  updateCarrierCrmLeadStatus = this.actions$.pipe(
    ofType(...[CarrierDetailsActions.UPDATE_CARRIER_CRM_LEAD_STATUS]),
    mergeMap((action: CarrierDetailsActions.UpdateCarrierCrmLeadStatus) =>
      this.detailsService.updateCarrierCrmLeadStatus(action.carrierCode, action.status).pipe(
        map((result) => {
          const newCrmLeadStatus = result.crmLeadStatus;
          return new CarrierDetailsActions.UpdateCarrierCrmLeadStatusSuccess(newCrmLeadStatus);
        }),
        catchError((x) => {
          return of(new CarrierDetailsActions.UpdateCarrierCrmLeadStatusError(x.error));
        })
      )
    )
  );

  @Effect()
  loadCarrierCrmLeadStatus = this.actions$.pipe(
    ofType(...[CarrierDetailsActions.LOAD_CARRIER_CRM_LEAD_STATUS]),
    mergeMap((action: CarrierDetailsActions.LoadCarrierCrmLeadStatus) =>
      this.detailsService.loadCarrierCrmLeadStatus(action.carrierCode).pipe(
        map((result) => {
          return new CarrierDetailsActions.LoadCarrierCrmLeadStatusSuccess(result);
        }),
        catchError((x) => {
          return of(new CarrierDetailsActions.LoadCarrierCrmLeadStatusError(x.message));
        })
      )
    )
  );

  constructor(private actions$: Actions, private detailsService: DetailsService) {}
}
