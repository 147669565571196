import { MapsAPILoader } from '@agm/core';
import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { Geolocation } from '../Models/Geolocation';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';

@Component({
  selector: 'truckload-google-address-lookup',
  templateUrl: './google-address-lookup.component.html',
  styleUrls: ['./google-address-lookup.component.scss'],
})
export class GoogleAddressLookupComponent implements OnInit {
  @ViewChild('search', { static: true })
  public searchElement: ElementRef;

  @Input()
  geolocation: Geolocation;
  @Input()
  isDirty: boolean;
  @Input()
  placeHolder = 'Enter a Location';

  @Output()
  isDirtyChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  locationUpdated: EventEmitter<Geolocation> = new EventEmitter();

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types: ['geocode'] });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.resetLocation();
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.setLocation(place);
        });
      });
    });
  }
  setLocation(place: google.maps.places.PlaceResult) {
    place.address_components.forEach((element) => {
      this.setAddressComponent(element);
    });
    if (this.geolocation.postalAddress) {
      this.geolocation.postalAddress = `${this.geolocation.streetaddress} ${this.geolocation.street}`;
    }
    if (place.geometry) {
      this.geolocation.latitude = place.geometry.location.lat();
      this.geolocation.longitude = place.geometry.location.lng();
    }
    this.locationUpdated.emit(this.geolocation);
    // TODO: if doesn't exist empty those things out or clear out the geolocation at the start of this function
  }
  setAddressComponent(addressComponent: google.maps.GeocoderAddressComponent) {
    if (addressComponent.types.includes('street_number')) {
      this.geolocation.streetaddress = addressComponent.long_name;
    }
    if (addressComponent.types.includes('route')) {
      this.geolocation.street = addressComponent.long_name;
    }
    if (addressComponent.types.includes('locality') || addressComponent.types.includes('neighborhood')) {
      this.geolocation.city = addressComponent.long_name;
    }
    if (addressComponent.types.includes('administrative_area_level_1')) {
      this.geolocation.stateProvince = addressComponent.short_name;
    }
    if (addressComponent.types.includes('postal_code')) {
      this.geolocation.postalCode = addressComponent.long_name;
    }
    if (addressComponent.types.includes('country')) {
      this.geolocation.countryCode = addressComponent.short_name;
    }
  }

  onInputChange(event) {
    this.isDirty = true;
    this.isDirtyChange.emit(this.isDirty);
    if (isNullOrEmptyString(event.target.value)) {
      this.resetLocation();
    }
  }

  private resetLocation(): void {
    this.geolocation.city = undefined;
    this.geolocation.street = undefined;
    this.geolocation.countryCode = undefined;
    this.geolocation.latitude = undefined;
    this.geolocation.longitude = undefined;
    this.geolocation.postalAddress = undefined;
    this.geolocation.postalCode = undefined;
    this.geolocation.stateProvince = undefined;
    this.geolocation.streetaddress = undefined;
  }
}
