import { Component, OnInit, Input } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import * as SearchActions from '../../actions/search.actions';
import { CarrierLaneSearchResult } from '../../models/CarrierLaneSearchResults';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'truckload-lane-grid',
  templateUrl: './lane-grid.component.html',
  styleUrls: ['./lane-grid.component.scss'],
})
export class LaneGridComponent implements OnInit {
  data: CarrierLaneSearchResult[];
  sort: SortDescriptor[] = [];
  canViewPostMatch: boolean;
  gridState: PageChangeEvent = { skip: 0, take: 25 };

  constructor(private store: Store<AppState>) {
    store
      .select((x) => x.Search.carrierLaneGridSort)
      .subscribe((x) => {
        this.sort = x;
      });

    this.store
      .select((x) => x.Search.canViewPostMatch)
      .subscribe((x) => {
        this.canViewPostMatch = x;
      });

    store
      .select((x) => x.Search.carrierLaneSearchResults)
      .subscribe((x) => {
        this.data = x;
      });

    this.store
      .select((x) => x.Search.carrierLaneGridPaging)
      .subscribe((x) => {
        if (x) {
          this.gridState = x;
        }
      });
  }

  ngOnInit() { }

  ngOnChanges(change) {
    this.data = change.data.currentValue;
  }

  public pageChange(event: PageChangeEvent): void {
    this.store.dispatch(new SearchActions.CarrierLaneGridPaging(event));
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.store.dispatch(new SearchActions.CarrierLaneGridSort(sort));
  }
}
